import { HttpClient } from "@angular/common/http";
import {  Injectable } from "@angular/core";
import { Observable} from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:'root'
})
export class UsuariosService{
    url: string
    constructor(private http: HttpClient){
        this.url = environment.apiURLNuevaBase + 'clientes/usuarios'
    }

    getUsuarios(): Observable<any>{
        return this.http.get<any>(this.url)
    }
}