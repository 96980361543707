<div style="text-align: right">
  <mat-icon (click)="dismiss()">close</mat-icon>
</div>
<mat-spinner *ngIf="!catalogoTopico; else showForm"></mat-spinner>
<ng-template #showForm>
  <div style="text-align: center">
    <mat-card-title>Agregar tarea</mat-card-title>
  </div>
  <mat-card-content>
    <form [formGroup]="agregarTareasForm">
      <mat-form-field>
        <mat-label>Tópico</mat-label>
        <mat-select formControlName="idTopicosCatalogo">
          <mat-option *ngFor="let topico of catalogoTopico" [value]="topico.id">
            {{topico.topico}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="agregarTareasForm.controls.idTopicosCatalogo.touched">Selecciona un valor</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Descripción</mat-label>
        <textarea matInput placeholder="Describa la tarea a solicitar" formControlName="funcion" rows="2" oninput="value = value.toUpperCase()"></textarea>
        <mat-error *ngIf="agregarTareasForm.controls.funcion.touched">El campo es requerido</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Comentario</mat-label>
        <textarea matInput placeholder="Escriba un comentario" formControlName="comentarios" rows="2" oninput="value = value.toUpperCase()"></textarea>
      </mat-form-field>
    </form>
    <mat-card-actions>
      <button mat-raised-button style="display: flex; float: right; margin-top: 30px;" color="primary"
              (click)="agregarTarea()" [disabled]="!this.agregarTareasForm.valid">Agregar tarea</button>
    </mat-card-actions>
  </mat-card-content>
</ng-template>
