<div class="card-container">
  <div class="row justify-content-center" *ngIf="!usuarios; else contenido">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #contenido>
    <div class="header">
      <h1>Agregar Al Carrito</h1>
    </div>
    <div class="form-container">
      <form [formGroup]="form">
        <mat-form-field>
          <input
            matInput
            placeholder="Correo Electrónico"
            aria-label="Correo Electrónico"
            formControlName="correo"
            [matAutocomplete]="auto"
            (input)="onKeyUp()"
            class="custom-input"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectUser($event.option.value)"
          >
            <mat-option
              *ngFor="let user of filteredUsers"
              [value]="user"
              class="custom-option"
            >
              {{ displayFn(user) }}
            </mat-option>
          </mat-autocomplete>

          <mat-error *ngIf="form.get('correo').hasError('emailNotFound')"
            >El correo ingresado no existe.</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <mat-label>Talla</mat-label>
          <mat-select formControlName="talla" required>
            <mat-option *ngFor="let talla of tallas" [value]="talla.id">
              {{ talla.talla }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="Cantidad"
            type="number"
            formControlName="cantidad"
          />
          <mat-error *ngIf="form.get('cantidad').hasError('min')"
            >La cantidad debe ser mayor que cero.</mat-error
          >
        </mat-form-field>
        <mat-checkbox formControlName="apartado">Apartado</mat-checkbox>

        <button
          mat-raised-button
          style="width: 100%"
          (click)="salir()"
          color="primary"
        >
          Aceptar
        </button>
      </form>
    </div>
  </ng-template>
</div>
