import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {Empresa} from '../../../Interface/productos/catalogos/empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresasNewService {
  url: string;
  urlNuevaBase: any;

  constructor(private http: HttpClient) {
    this.url = environment.apiURLNuevaBase + 'productos/catalogos/empresa';
    this.urlNuevaBase = environment.apiURLNuevaBase;
  }

  getActivos(): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(this.url + '/activos');
  }

  activarDesactivarProductos(idProducto, idEmpresa, activar): Observable<any> {
    return this.http.put<any>(this.urlNuevaBase + `productos/catalogos/activar-desactivar/empresa/${idProducto}/${idEmpresa}`, activar);
  }

  getEmpresaProductoByIdProducto(idProducto): Observable<any[]> {
    return this.http.get<any[]>(this.url + `/by-id-producto/${idProducto}`);
  }
  getEmpresasNew(): Observable<any[]> {
    return this.http.get<any[]>(this.urlNuevaBase + 'productos/catalogos/empresa/activos');
  }
}
