<table mat-table [dataSource]="ordenes" class="mat-elevation-z8" matSort>
  <ng-container matColumnDef="fecha">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha</th>
    <td mat-cell *matCellDef="let element"> {{element.fechaRegistro | date:'medium'}}</td>
  </ng-container>
  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
    <td mat-cell *matCellDef="let element"> {{element.nombre}} {{element.apellidosCliente}}</td>
  </ng-container>
  <ng-container matColumnDef="telefono">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono</th>
    <td mat-cell *matCellDef="let element"> {{element.telefono}}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
    <td mat-cell *matCellDef="let element"> {{element.correo}}</td>
  </ng-container>
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total</th>
    <td mat-cell *matCellDef="let element"> {{element.total | currency : 'MXN'}}</td>
  </ng-container>
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef>Acciones</th>
    <td mat-cell *matCellDef="let element"  style="text-align: center">
      <mat-icon aria-hidden="false" style="cursor: pointer;"
      (click)="navigateToDetails(element.numeroFolio)"
                 matTooltip="Detalles de orden">
        search
      </mat-icon>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
