import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../service/nav.service';
import { LoginService } from '../../../core/services/login.service';
import { ProductosService } from 'src/app/core/services/productos.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

    public menuItems: Menu[];
    public url: any;
    public fileurl: any;
    user: any;

    constructor(private router: Router, public navServices: NavService, private loginService: LoginService, private productosServicce: ProductosService) {
        this.loginService.getUser().subscribe(data => {
            this.user = data;
            sessionStorage.setItem("user_name", data.name);
        });
        this.navServices.items.subscribe(menuItems => {
            this.menuItems = menuItems;
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    menuItems.filter(items => {
                        if (items.path === event.url) {
                            this.setNavActive(items);
                        }
                        if (!items.children) {
                            return false;
                        }
                        items.children.filter(subItems => {
                            if (subItems.path === event.url) {
                                this.setNavActive(subItems);
                            }
                            if (!subItems.children) {
                                return false;
                            }
                            subItems.children.filter(subSubItems => {
                                if (subSubItems.path === event.url) {
                                    this.setNavActive(subSubItems);
                                }
                            });
                        });
                    });
                }
            });
        });
    }

    // Active Nave state
    setNavActive(item) {
        this.menuItems.filter(menuItem => {
            if (menuItem != item) {
                menuItem.active = false;
            }
            if (menuItem.children && menuItem.children.includes(item)) {
                menuItem.active = true;
            }
            if (menuItem.children) {
                menuItem.children.filter(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true;
                        submenuItems.active = true;
                    }
                });
            }
        });
    }

    handleButtonClick(title: string) {
        if (!(title.includes("SneakerStore") || title.includes("ReligionSneakers") || title.includes("GoldenLaces"))) return;

        const domainMap = {
          "SneakerStore": "sneakerstore.com.mx",
          "ReligionSneakers": "religionsneakers.com",
          "GoldenLaces": "goldenlaces.com.mx"
        }

        this.productosServicce.getSitemap().subscribe(data => {
            const tab = '\t';
            const doubleTab = tab + tab;
            const cuadrupleTab = doubleTab + doubleTab;
            const newLine = '\r\n';

            const PRIORITY = 0.8;

            const generatedUrlsMap = data.map(product => {
                const fechaRegistro = product.fechaRegistro?.split(' ')[0];

                let url = `${tab}<url>`;
                let loc = `${doubleTab}<loc>https://${domainMap[title]}/productos/detalle/${product.url}</loc>`;
                let lastmod = `${doubleTab}<lastmod>${fechaRegistro}</lastmod>`;
                let changeFref = `${doubleTab}<changefreq>daily</changefreq>`;
                let priority = `${doubleTab}<priority>${PRIORITY}</priority>`;

                url += (newLine);
                url += (loc);
                url += (newLine)
                url += (lastmod);
                url += (newLine);
                url += (changeFref);
                url += (newLine);
                url += (priority);
                url += (newLine);
                url += (`${tab}</url>`);

                return url;
            }).join(newLine);

            const sitemap = xmlBase + newLine + generatedUrlsMap + newLine + '</urlset>';

            const file = new Blob([sitemap], { type: 'text/xml' });

            const downloadButton = document.createElement("a");
            const url = URL.createObjectURL(file);

            downloadButton.href = url;
            downloadButton.download = `productos-sitemap-${new Date().getMilliseconds()}`;
            document.body.appendChild(downloadButton);
            downloadButton.click();

            setTimeout(function () {
                document.body.removeChild(downloadButton);
                window.URL.revokeObjectURL(url);
            }, 0);
        });
    }

    // Click Toggle menu
    toggletNavActive(item) {
        if (!item.active) {
            this.menuItems.forEach(a => {
                if (this.menuItems.includes(item)) {
                    a.active = false;
                }
                if (!a.children) {
                    return false;
                }
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false;
                    }
                });
            });
        }
        item.active = !item.active;
    }

    // Fileupload
    readUrl(event: any) {
        if (event.target.files.length === 0) {
            return;
        }
        // Image upload validation
        const mimeType = event.target.files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        // Image upload
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            this.url = reader.result;
        };
    }

}


const xmlBase = `
<?xml version="1.0" encoding="UTF-8"?>
<urlset
    xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
    http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd"
>`;