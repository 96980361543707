<div class="page-main-header" [class.open]="open">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper"><a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
                                                                            src="assets/images/dashboard/sslogo.png"
                                                                            alt="" style="height: 56px;"></a></div>
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a>
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
            </app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">
        <!--<li>-->
        <!--<form class="form-inline search-form">-->
        <!--<div class="form-group">-->
        <!--<input class="form-control-plaintext" [class.open]="isOpenMobile" type="search"-->
        <!--placeholder="Buscar..">-->
        <!--<span class="d-sm-none mobile-search">-->
        <!--<app-feather-icons [icon]="'search'" (click)="isOpenMobile=!isOpenMobile">-->
        <!--</app-feather-icons>-->
        <!--</span>-->
        <!--</div>-->
        <!--</form>-->
        <!--</li>-->
        <!--<li>-->
        <!--<a href="javascript:void(0)" class="text-dark" toggleFullscreen>-->
        <!--<app-feather-icons [icon]="'maximize-2'"></app-feather-icons>-->
        <!--</a>-->
        <!--</li>-->
        <!--<li class="onhover-dropdown"><a class="txt-dark" href="javascript:void(0)">-->
        <!--<h6>EN</h6>-->
        <!--</a>-->
        <!--<ul class="language-dropdown onhover-show-div p-20">-->
        <!--<li><a href="javascript:void(0)" data-lng="en"><i class="flag-icon flag-icon-is"></i>-->
        <!--Ingles</a></li>-->
        <!--<li><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i>-->
        <!--Español</a></li>-->
        <!--<li><a href="javascript:void(0)" data-lng="pt"><i class="flag-icon flag-icon-uy"></i>-->
        <!--Portuguese</a></li>-->
        <!--<li><a href="javascript:void(0)" data-lng="fr"><i class="flag-icon flag-icon-nz"></i>-->
        <!--Frances</a></li>-->
        <!--</ul>-->
        <!--</li>-->
        <!--<li class="onhover-dropdown">-->
        <!--<span class="badge badge-pill badge-primary pull-right notification-badge">3</span>-->
        <!--<app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>-->
        <!--<ul class="notification-dropdown onhover-show-div">-->
        <!--<li>Notificaciones <span class="badge badge-pill badge-primary pull-right">3</span></li>-->
        <!--<li>-->
        <!--<div class="media">-->
        <!--<div class="media-body">-->
        <!--<h6 class="mt-0"><span>-->
        <!--<app-feather-icons class="shopping-color" [icon]="'shopping-bag'">-->
        <!--</app-feather-icons>-->
        <!--</span>Su pedido esta listo para envio..!-->
        <!--</h6>-->
        <!--<p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>-->
        <!--</div>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li>-->
        <!--<div class="media">-->
        <!--<div class="media-body">-->
        <!--<h6 class="mt-0 txt-success"><span>-->
        <!--<app-feather-icons class="download-color font-success" [icon]="'download'">-->
        <!--</app-feather-icons>-->
        <!--</span>Descarga completada</h6>-->
        <!--<p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>-->
        <!--</div>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li>-->
        <!--<div class="media">-->
        <!--<div class="media-body">-->
        <!--<h6 class="mt-0 txt-danger"><span>-->
        <!--<app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'">-->
        <!--</app-feather-icons>-->
        <!--</span>250 MB archivos basura</h6>-->
        <!--<p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>-->
        <!--</div>-->
        <!--</div>-->
        <!--</li>-->
        <!--<li class="txt-dark"><a href="javascript:void(0)">Todas las</a> notificaciones</li>-->
        <!--</ul>-->
        <!--</li>-->
        <!--                <li><a>-->
        <!--                        <app-feather-icons class="right_side_toggle" [icon]="'message-square'"-->
        <!--                            (click)="right_side_bar()">-->
        <!--                        </app-feather-icons><span class="dot"></span>-->
        <!--                    </a></li>-->

        <li style="margin-top: 2px;" class="onhover-dropdown">
          <img src="/assets/icons/bot.svg" matTooltip="Asistente" (click)="abrirAsistente()">
        </li>
        
        <!-- Buscador de productos -->
        <li class="onhover-dropdown">
          <app-feather-icons [icon]="'search'" (click)="abrirBuscador()" class="icono-grande" matTooltip="Buscador de  Productos"></app-feather-icons>
        </li>

        <li class="onhover-dropdown">
          <app-feather-icons [icon]="'bell'" (click)="detalleTickets()" class="icono-grande" matTooltip="Tickets pendientes"></app-feather-icons>
          <!-- Botón rojo con número de tickets -->
          <span class="badge badge-pill badge-danger notification-badge">{{(tickets?.length > 0) ? tickets[0].cantidad : '-'}}</span>
        </li>

        <li class="onhover-dropdown">
          <div class="align-items-center">
            <img class="align-self-center pull-right img-50 rounded-circle"
                 src="assets/images/dashboard/unnamed.png" alt="header-user">
            <div class="dotted-animation">
              <span class="animate-circle"></span>
              <span class="main-circle"></span>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <a (click)="abrirModalTareas()">
                <app-feather-icons [icon]="'edit'"></app-feather-icons>
                Solicitar tarea a T.I.
              </a>
            </li>
            <li>
              <a [routerLink]="['/usuario/solicitudes-ti']">
                <app-feather-icons [icon]="'eye'"></app-feather-icons>
                Tareas pendientes T.I.
              </a>
            </li>
            <li>
              <a (click)="logout()">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>
                  Cerrar Sesion
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
