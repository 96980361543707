import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CantidadesTotales} from '../../Interface/dashboard/cantidades-totales';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportesService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiURLNuevaBase}dashboard`;
  }

  getCantidades(opcion: number): Observable<CantidadesTotales> {
    return this.http.get<CantidadesTotales>(`${this.url}/cantidades/${opcion}`);
  }
}
