import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl + 'login';
  }

  logout(): Observable<any> {
    return this.http.put(this.url + '/logout/' + jwt_decode(sessionStorage.getItem('token')).sub, null);
  }

  sesiones(): Observable<any> {
    return this.http.get<any>(this.url + '/sesiones');
  }
}
