import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IntegracionGPTService {

  private urlVerificacion: string;

  constructor(private http: HttpClient) {
    this.urlVerificacion = `${environment.apiURLNuevaBase}verificacion/`;
  }

  preguntaAsistente(body): Observable<any> {
    return this.http.post(`${this.urlVerificacion}asistente-gpt`, body);
  }
}
