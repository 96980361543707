import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { Ticket } from 'src/app/core/Interface/sales/ticket';

@Injectable({
  providedIn: 'root'
})
export class CrearTicketService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl + 'tickets';
  }

  get(): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(this.url);
  }

  getByUserName(nombreResponsable: string, administrador: boolean = false): Observable<Ticket[]> {
    return (administrador) ? this.http.get<Ticket[]>(this.url) : this.http.post<Ticket[]>(this.url + '/getByUserNameTicket', {"nombreResponsable": nombreResponsable});
  }

  getById(id: number): Observable<Ticket> {
    return this.http.get<Ticket>(this.url + '/' + id);
  }

  reasignarTicket(actualizarTicket: Ticket): Observable<any> {
    return this.http.put(this.url + '/reasignar-ticket', actualizarTicket);
  }

  cambiarEstatusTicket(actualizarTicket: Ticket): Observable<any> {
    return this.http.put(this.url + '/cambiar-estatus-ticket', actualizarTicket);
  }

  ticketsUsuario(nombreResponsable: String): Observable<any> {
    return this.http.post(this.url + '/tickets-usuario', {"nombreResponsable": nombreResponsable});
  }

  deleteById(id: number): Observable<any> {
    return this.http.delete(this.url + '/' + id);
  }

  post(crearTicket: Ticket): Observable<any> {
    return this.http.post(this.url, crearTicket);
  }

}
