import { Injectable, HostListener, Inject, Directive } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from './windows.service';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  hidden?: boolean;
  isButton?: boolean;
}

@Directive()
@Injectable({
  providedIn: 'root'
})

export class NavService {
  public items;
  permisos: any;
  MENUITEMS: Menu[];

  constructor(@Inject(WINDOW) private window) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
    this.permisos = JSON.parse(sessionStorage.getItem('permisos'));

    this.MENUITEMS = [
      {
        path: '/dashboard/default',
        title: 'Dashboard',
        icon: 'home',
        type: 'link',
        hidden: false,
        badgeType: 'primary',
      },
      {
        title: 'Productos', icon: 'package', type: 'sub', hidden: !this.permisos.productos,
        children: [
          {
            path: '/productos/alta-producto',
            title: 'Agregar productos',
            type: 'link',
            hidden: !(this.permisos.productos && this.permisos.productos.anadirProducto)
          },
          {
            path: '/productos/product-list',
            title: 'Lista de productos',
            type: 'link',
            hidden: !(this.permisos.productos && this.permisos.productos.listaProductos)
          },
          {
            path: '/productos/lanzamientos',
            title: 'Lanzamientos',
            type: 'link',
            hidden: !(this.permisos.productos && this.permisos.productos.listaProductos)
          },
          /*          {
                      path: '/productos/product-list/1',
                      title: 'Lista de productos Sneakerstore',
                      type: 'link',
                      hidden: !(this.permisos.productos && this.permisos.productos.listaProductos)
                    },
                    {
                      path: '/productos/product-list/2',
                      title: 'Lista de productos Religionstore',
                      type: 'link',
                      hidden: !(this.permisos.productos && this.permisos.productos.listaProductos)
                    },*/
          /*
          {
            path: '/productos/add-stock',
            title: 'Añadir stock',
            type: 'link',
            hidden: !(this.permisos.productos && this.permisos.productos.stock)
          },*/
          {
            path: '/productos/catalogo-proveedor',
            title: 'Supplier Catalog',
            type: 'link',
            hidden: !(this.permisos.productos?.stockFisicoTotal)
          },
          // {
          //   path: '/productos/stock',
          //   title: 'Stock',
          //   type: 'link',
          //   hidden: !(this.permisos.productos?.stockFisicoTotal)
          // },
          {
            path: '/productos/productos-stock',
            title: 'Productos Stock',
            type: 'link',
            hidden: !(this.permisos.productos?.stockFisicoTotal || this.permisos.productos?.totalStock)
          },
          // hidden: !(this.permisos['entradas-salidas']?.historicoAclaraciones || this.permisos['verificacion']?.historicoAclaraciones || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)

          {
            path: '/productos/google-shopping',
            title: 'Google shopping',
            type: 'link',
            hidden: !(this.permisos.productos && this.permisos.productos.outlet)
          },
          /*{
            path: '/productos/products-outlet',
            title: 'Productos en Outlet',
            type: 'link',
            hidden: !(this.permisos.productos && this.permisos.productos.outlet)
          },*/
          /*{
            path: '/productos/entrega-inmediata',
            title: 'Productos de entrega inmediata',
            type: 'link',
            hidden: !(this.permisos.productos && this.permisos.productos.entregaInmediata)
          },*/
          /*          {
                      path: '/products/catalogos',
                      title: 'Catalogos',
                      type: 'link',
                    },*/
          /*{
            path: '/productos/products-concession',
            title: 'Productos en solicitud de concesión',
            type: 'link',
          },*/
          {
            title: 'Catalogos',
            type: 'sub',
            hidden: !(this.permisos.productos?.categorias || this.permisos.productos?.marcas),
            children: [
              {
                path: '/productos/category',
                title: 'Categorias',
                type: 'link',
                hidden: !(this.permisos.productos && this.permisos.productos.categorias),
              },
              {
                path: '/productos/marcas',
                title: 'Marcas',
                type: 'link',
                hidden: !(this.permisos.productos && this.permisos.productos.marcas)
              },
              {
                path: '/productos/submarcas',
                title: 'SubMarcas',
                type: 'link',
                hidden: !(this.permisos.productos && this.permisos.productos.marcas)
              },
              {
                path: '/productos/colores',
                title: 'Colores',
                type: 'link',
                hidden: !(this.permisos.productos)
              },

            ]
          },
          {
            title: 'Generar SITEMAP',
            type: 'sub',
            hidden: false,
            children: [
              {
                path: '#',
                title: 'SneakerStore',
                type: "link",
                isButton: true
              },
              {
                path: '#',
                title: 'ReligionSneakers',
                type: 'link',
                isButton: true
              },
              {
                path: '#',
                title: 'GoldenLaces',
                type: 'link',
                isButton: true
              },
            ]
          },
          {
            path: "/productos/sugerencias",
            title: "Sugerencias",
            type: "link",
          },
        ]
      },
      {
        title: 'Ventas', icon: 'dollar-sign', type: 'sub', active: false, hidden: !this.permisos.ventas,
        children: [
          {
            path: '/sales/crear-orden', title: 'Crear orden', type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.crearOrden)
          },
          {
            path: '/sales/crear-cotizacion', title: 'Crear cotización', type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.crearOrden)
          },
          {
            path: '/sales/ordenes',
            title: 'Ordenes',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.ordenes)
          },
          {
            path: '/sales/crear-subasta', title: 'Crear subasta', type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.crearSubasta)
          },
          {
            path: '/sales/subastas',
            title: 'Subastas',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.ordenes)
          },
          {
            path: '/sales/vieja-logica',
            title: 'Ordenes vieja lógica',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.ordenes)
          },
          {
            path: '/sales/mis-ordenes',
            title: 'Mis ordenes',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.misOrdenes)
          },
          {
            path: '/sales/aprobadas-semanales',
            title: 'Aprobadas semanales',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.aprobadasSemanales)
          },
          // {
          //   title: 'Reportes',
          //   type: 'sub',
          //   active: false,
          //   hidden: !(this.permisos.reportes),
          //   children: [
          //     {
          //       path: '/sales/reportes/mas-vendidos', title: 'Productos más vendidos', type: 'link',
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.masVendidos)
          //     },
          //     {
          //       path: '/sales/reportes/ventas-empleados', title: 'Ventas por empleado', type: 'link',
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          //     },
          //     {
          //       path: '/sales/reportes/ventas-empresa', title: 'Ventas por empresa', type: 'link',
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          //     },
          //     {
          //       path: '/sales/reportes/ventas-canal',
          //       title: 'Ventas por canal',
          //       type: 'link',
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          //     },
          //     {
          //       path: "/sales/reportes/pares-vendidos-marca",
          //       title: "Pares vendidos por marca",
          //       type: "link",
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          //     },
          //     {
          //       path: "/sales/reportes/ingresos-plataforma",
          //       title: "Ingresos por plataforma",
          //       type: "link",
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          //     },
          //     {
          //       path: "/sales/reportes/ventas-diarias-marca",
          //       title: "Ventas diarias por marca",
          //       type: "link",
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          //     },
          //     {
          //       path: "/sales/reportes/depositos-efectivo-transferencia",
          //       title: "Depósitos en efectivo y transferencia",
          //       type: "link",
          //       hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          //     },
          //   ]
          // },
          {
            path: '/sales/prospects',
            title: 'Carritos abandonados',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.aprobadasSemanales)
          },
          {
            path: '/sales/abandonados',
            title: 'Carritos abandonados automáticamente',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.AbandonadosAutomaticamente)
          },
          {
            path: '/sales/prospects-prime',
            title: 'Prospectos Prime',
            type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.aprobadasSemanales)
          },
          {
            path: '/sales/suscripciones-lanzamientos-preventa', title: 'Suscripciones a lanzamientos y preventa', type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.SuscripcionesLanzamiento)
          },
          {
            path: '/sales/historial-contracargo', title: 'Historiales de Contracargo', type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.aprobadasSemanales)
          }, {
            path: '/sales/comentarios', title: 'Comentarios', type: 'link',
            hidden: !(this.permisos.ventas && this.permisos.ventas.aprobadasSemanales)
          }
        ]
      },
      {
        title: 'Reportes',
        icon: 'bar-chart',
        type: 'sub',
        active: false,
        hidden: !(this.permisos.reportes),
        children: [
          {
            path: '/reportes/mas-vendidos', title: 'Productos más vendidos', type: 'link',
            hidden: !(this.permisos.reportes && this.permisos.reportes.masVendidos)
          },
          {
            path: '/reportes/ventas-empleados', title: 'Ventas por empleado', type: 'link',
            hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpleado)
          },
          {
            path: '/reportes/ventas-empresa', title: 'Ventas por empresa', type: 'link',
            hidden: !(this.permisos.reportes && this.permisos.reportes.ventasEmpresa)
          },
          {
            path: '/reportes/ventas-canal',
            title: 'Ventas por canal',
            type: 'link',
            hidden: !(this.permisos.reportes && this.permisos.reportes.ventasCanal)
          },
          {
            path: "/reportes/pares-vendidos-marca",
            title: "Pares vendidos por marca",
            type: "link",
            hidden: !(this.permisos.reportes && this.permisos.reportes.ventasMarca)
          },
          {
            path: "/reportes/ingresos-plataforma",
            title: "Ingresos por plataforma",
            type: "link",
            hidden: !(this.permisos.reportes && this.permisos.reportes.ingresosPlataforma)
          },
          {
            path: "/reportes/ventas-diarias-marca",
            title: "Ventas diarias por marca",
            type: "link",
            hidden: !(this.permisos.reportes && this.permisos.reportes.ventasDiariaMarca)
          },
          {
            path: "/reportes/depositos-efectivo-transferencia",
            title: "Depósitos en efectivo y transferencia",
            type: "link",
            hidden: !(this.permisos.reportes && this.permisos.reportes.deposito)
          },
        ]
      },
      {
        title: 'Recursos humanos',
        icon: 'paperclip',
        type: 'sub',
        active: false,
        hidden: !this.permisos.recursosHumanos,
        children: [
          {
            path: '/recursos-humanos/empleados', title: 'Empleados', type: 'link',
            hidden: !(this.permisos.recursosHumanos && this.permisos.recursosHumanos.empleados)
          },
        ]
      },
      {
        title: 'Cobranza', icon: 'clipboard', type: 'sub', active: false, hidden: !this.permisos.cobranza,
        children: [
          {
            path: '/cobranza/pendientes-pago', title: 'Pendientes de pago', type: 'link',
            hidden: !(this.permisos.cobranza && this.permisos.cobranza.pendientesPago)
          },
          {
            path: '/cobranza/prospectos-venta',
            title: 'Prospectos de venta',
            type: 'link',
            hidden: !this.permisos.cobranza
          }
        ]
      },
      {
        title: 'Cupones', icon: 'tag', type: 'sub', active: false, hidden: !this.permisos.cupones,
        children: [
          {
            path: '/cupones/create-coupons', title: 'Crear cupón', type: 'link',
            hidden: !(this.permisos.cupones && this.permisos.cupones.crearCupon)
          },
          {
            path: '/cupones/list-coupons', title: 'Lista de Cupones', type: 'link',
            hidden: !(this.permisos.cupones && this.permisos.cupones.listaCupones)
          },
        ]
      },
      {
        title: 'Descuento', icon: 'tag', type: 'sub', active: false, hidden: !this.permisos.cupones,
        children: [
          {
            path: '/discounts/descuento-rango', title: 'Descuento por rango', type: 'link',
            hidden: !(this.permisos.cupones && this.permisos.cupones.crearCupon)
          }
        ]
      },
      {
        title: 'Clientes', icon: 'user', type: 'sub', active: false, hidden: !this.permisos.clientes,
        children: [
          {
            path: '/clientes/citas', title: 'Citas', type: 'link',
            hidden: !(this.permisos.clientes && this.permisos.clientes.citas)
          },
          {
            path: '/clientes/clientes-invitado', title: 'Invitados', type: 'link',
            hidden: !(this.permisos.clientes && this.permisos.clientes.invitados)
          },
          {
            path: '/clientes/clientes-usuarios', title: 'Registrados',
            hidden: !(this.permisos.clientes && this.permisos.clientes.registrados), type: 'link'
          },
          {
            path: '/clientes/newsletter', title: 'Newsletter', type: 'link',
            hidden: !(this.permisos.clientes && this.permisos.clientes.newsletter)
          },
          {
            path: '/clientes/prospectos', title: 'Prospectos', type: 'link',
            hidden: !(this.permisos.clientes && this.permisos.clientes.prospectos)
          },
          {
            path: '/clientes/directorio-clientes', title: 'Directorio Clientes', type: 'link',
            hidden: !(this.permisos.clientes)
          },
        ]
      },
      {
        title: 'Marketing', icon: 'monitor', type: 'sub', active: false, hidden: !this.permisos.marketing,
        children: [
          {
            path: '/marketing/clientes-invitado', title: 'Invitados', type: 'link',
            hidden: !(this.permisos.marketing && this.permisos.marketing.invitados)
          },
          {
            path: '/marketing/clientes-usuarios', title: 'Registrados', type: 'link',
            hidden: !(this.permisos.marketing && this.permisos.marketing.registrados)
          },
          {
            path: '/marketing/newsletter', title: 'Newsletter', type: 'link',
            hidden: !(this.permisos.marketing && this.permisos.marketing.newsletter)
          },
          {
            path: '/marketing/prospectos', title: 'Prospectos', type: 'link',
            hidden: !(this.permisos.marketing && this.permisos.marketing.prospectos)
          },
          {
            path: '/marketing/registros-landings', title: 'Registros de landings', type: 'link',
            hidden: !(this.permisos.marketing && this.permisos.marketing.registrosLandings)
          },
          {
            path: '/marketing/multiples-imagenes', title: 'Múltiples imágenes', type: 'link',
            hidden: !(this.permisos.marketing && this.permisos.marketing.multiplesImagenes)
          },
          {
            path: '/marketing/encuestas', title: 'Encuestas', type: 'link',
            hidden: !(this.permisos.marketing && this.permisos.marketing.multiplesImagenes)
          },
          {
            title: 'Descuentos',
            type: 'sub',
            children: [
              {
                path: '/marketing/crear-descuento', title: 'Crear descuento', type: 'link',
                hidden: !(this.permisos.finanzas && this.permisos.finanzas.aprobacionPagos)
              },
              {
                path: '/marketing/administrar-descuentos', title: 'Administrar descuentos', type: 'link',
                hidden: !(this.permisos.finanzas && this.permisos.finanzas.aprobacionPagos),
              },
            ],
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.aprobacionPagos)
          },
        ]
      },
      /*{
        title: 'Benefits', icon: 'user-check', type: 'sub', active: false, hidden: !this.permisos.benefits,
        children: [
          {
            title: 'Control de usuarios', type: 'sub', active: false,
            hidden: !(this.permisos.benefits && this.permisos.benefits.controlUsuarios), children: [
              {path: '/benefits/usuarios/1', title: 'Alta', type: 'link'},
              {path: '/benefits/usuarios/2', title: 'Baja', type: 'link'},
            ]
          },
          {
            title: 'Catálogos', type: 'sub', active: false,
            hidden: !(this.permisos.benefits && this.permisos.benefits.catalogos), children: [
              {
                path: '/benefits/catalogos/instituciones', title: 'Instituciones',
                type: 'link', hidden: !(this.permisos.benefits && this.permisos.benefits.instituciones)
              },
              {
                path: '/benefits/catalogos/beneficiarios', title: 'Beneficiarios',
                type: 'link', hidden: !(this.permisos.benefits && this.permisos.benefits.beneficiarios)
              },
            ]
          }
        ]
      },*/
      /*{
        title: 'Financial', icon: 'pocket', type: 'sub', hidden: !this.permisos.financial, active: false,
        children: [
          {
            path: '/financial/recibos', title: 'Recibos',
            hidden: !(this.permisos.financial && this.permisos.financial.financial),
            type: 'link'
          },
        ]
      },*/
      {
        title: 'Atención a clientes', icon: 'help-circle', type: 'sub', active: false, hidden: !this.permisos.atencionClientes,
        children: [
          {
            path: '/atencion-clientes/aclaraciones', title: 'Aclaraciones', type: 'link',
            hidden: !(this.permisos.atencionClientes && this.permisos.atencionClientes.aclaraciones)
          },
          /*{
            path: '/atencion-clientes/seguimiento', title: 'Seguimiento', type: 'link',
            hidden: !(this.permisos.atencionClientes && this.permisos.atencionClientes.seguimiento)
          },*/
          {
            path: '/atencion-clientes/verificacion', title: 'Verificación', type: 'link',
            hidden: !(this.permisos.atencionClientes && this.permisos.atencionClientes.verificacion)
          },
          {
            path: '/atencion-clientes/mensajes', title: 'Mensajes', type: 'link',
          },
          {
            path: '/atencion-clientes/cupones-diferencia', title: 'Cupones', type: 'link',
          },
          {
            path: '/atencion-clientes/archivos-proveedor', title: 'Archivos Proveedor', type: 'link',
          },
          {
            path: '/atencion-clientes/actualizar-datos', title: 'Actualizar Datos', type: 'link',
            hidden: !(this.permisos.atencionClientes && this.permisos.atencionClientes.verificacion)
          }
        ]
      },
      {
        title: 'Usuarios', icon: 'users', type: 'sub', active: false,
        hidden: !this.permisos.usuarios, children: [
          {
            path: '/usuarios/administrador', title: 'Administrador', type: 'link',
            hidden: !(this.permisos.usuarios && this.permisos.usuarios.administrador)
          },
          {
            path: '/usuarios/administrador-permisos', title: 'Administrador de permisos', type: 'link',
            hidden: !(this.permisos.usuarios && this.permisos.usuarios.administradorPermisos)
          },
          {
            path: '/usuarios/inicio-sesion', title: 'Control de sesiones', type: 'link',
            hidden: !(this.permisos.usuarios && this.permisos.usuarios.controlSesiones)
          },
          {
            path: '/usuarios/vendedor-externo', title: 'Vendedores externos', type: 'link',
            hidden: !(this.permisos.usuarios && this.permisos.usuarios.vendedorExterno)
          },
        ]
      },
      {
        title: 'Finanzas', icon: 'percent', type: 'sub', hidden: !this.permisos.finanzas, children: [
          {
            path: '/finanzas/aprobar-pagos', title: 'Aprobación pagos', type: 'link',
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.aprobacionPagos)
          },
          {
            // path: '/finanzas/pagos-aprobados', title: 'Pagos aprobados', type: 'link',
            // path: '/finanzas/pagos-aprobados',
            // type: 'link',
            type: 'sub',
            title: 'Pagos validados',
            // hidden: !(this.permisos.finanzas && this.permisos.finanzas.pagosAprobados),
            children: [
              {
                path: '/finanzas/pagos/2', title: 'Aceptados', type: 'link',
                // hidden: !(this.permisos.finanzas && this.permisos.finanzas.pagosAprobados),
              },
              {
                path: '/finanzas/pagos/3', title: 'Rechazados', type: 'link',
                // hidden: !(this.permisos.finanzas && this.permisos.finanzas.pagosAprobados),
              },
            ]
          },
          {
            path: '/finanzas/gastos-mensuales', title: 'Gastos mensuales', type: 'link',
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.gastosMensuales)
          },
          {
            path: '/finanzas/gastos-publicidad', title: 'Gastos redes sociales', type: 'link',
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.redessociales)
          },
          {
            path: '/finanzas/nomina', title: 'Gastos Nomina', type: 'link',
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.nomina)
          },
          {
            path: '/finanzas/guias', title: 'Gastos Guías', type: 'link',
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.gastosGuias)
          },
          {
            path: '/finanzas/mercancia', title: 'Gastos Mercancía', type: 'link',
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.gastosMercancia)
          },
          {
            title: 'Catalogos',
            type: 'sub',
            hidden: !(this.permisos.finanzas && this.permisos.finanzas.catalogos),
            children: [
              {
                path: '/finanzas/catalogos/costos', title: 'Costos', type: 'link',
                hidden: !(this.permisos.finanzas && this.permisos.finanzas.costos)
              },
              {
                path: '/finanzas/catalogos/redes-sociales', title: 'Redes sociales', type: 'link',
                hidden: !(this.permisos.finanzas && this.permisos.finanzas.redessociales)
              },
            ]
          },
        ]
      },
      {
        title: 'Vendedores externos', icon: 'share', type: 'sub', hidden: !this.permisos.distribuidores, children: [
          {
            path: '/distribuidores/administrador', title: 'Lista de VE', type: 'link',
            hidden: !(this.permisos.distribuidores && this.permisos.distribuidores.listaDistribuidores)
          },
          {
            path: '/distribuidores/administrar-usuarios', title: 'Administrar usuarios', type: 'link',
            hidden: !(this.permisos.distribuidores && this.permisos.distribuidores.listaDistribuidores)
          },
          {
            path: '/distribuidores/veFaqs', title: "Preguntas Frecuentes VE", type: 'link',
            hidden: !(this.permisos.distribuidores && this.permisos.distribuidores.listaDistribuidores)
          },
          {
            path: '/distribuidores/notificaciones', title: "Notificaciones VE", type: 'link',
            hidden: !(this.permisos.distribuidores && this.permisos.distribuidores.listaDistribuidores)
          },
          {
            path: '/distribuidores/prospectos', title: "prospectos VE", type: 'link',
            hidden: !(this.permisos.distribuidores && this.permisos.distribuidores.listaDistribuidores)
          }
        ]
      },

      {
        title: 'Vendedores Externos', icon: 'share', type: 'sub', hidden: !this.permisos.dealers, children: [
          {
            path: '/vendedor-externo/usuarios', title: 'Vendedores', type: 'link',
            hidden: !(this.permisos.dealers)
          },
          {
            path: '/vendedor-externo/metas', title: 'Metas', type: 'link',
            hidden: !(this.permisos.dealers)
          },
          {
            path: '/vendedor-externo/comisiones', title: 'Comisiones', type: 'link',
            hidden: !(this.permisos.dealers)
          },
          {
            path: '/vendedor-externo/pagos', title: "Pagos", type: 'link',
            hidden: !(this.permisos.dealers)
          },
          {
            path: '/vendedor-externo/notificaciones', title: "Notificaciones", type: 'link',
            hidden: !(this.permisos.dealers)
          },
          {
            path: '/vendedor-externo/faqs', title: "Preguntas Frecuentes", type: 'link',
            hidden: !(this.permisos.dealers)
          },
          {
            path: '/vendedor-externo/historico-comisiones', title: "Histórico de comisiones", type: 'link',
          },
          {
            path: '/vendedor-externo/historico-metas', title: "Histórico de metas", type: 'link',
          }
        ]
      },

      /*{
        title: 'Lotes', icon: 'archive', type: 'sub', hidden: !this.permisos.lotes, children: [
          {
            path: '/lotes/crear-lote', title: 'Crear lote', type: 'link',
            hidden: !(this.permisos.lotes && this.permisos.lotes.crearLote)
          },
          {
            path: '/lotes/ver-lotes', title: 'Ver lotes', type: 'link',
            hidden: !(this.permisos.lotes && this.permisos.lotes.verLotes)
          },
        ]
      },*/
      {
        title: 'Ecommerce', icon: 'code', type: 'sub', hidden: !this.permisos.ecommerce, children: [
          {
            path: '/ecommerce/texto-principal', title: 'Texto inicio', type: 'link',
            hidden: !(this.permisos.ecommerce && this.permisos.ecommerce.textoInicio),
          }, {
            path: '/ecommerce/imagen-banner', title: 'Imagenes banner', type: 'link',
            hidden: !(this.permisos.ecommerce && this.permisos.ecommerce.imagenesBanner),
          },
        ]
      },
      {
        title: 'Administrador', icon: 'shield', type: 'sub', hidden: !this.permisos.administrador, children: [
          {
            path: '/administrador/productos', title: 'Productos', type: 'link',
            hidden: !(this.permisos.administrador && this.permisos.administrador.productos),
          },
          {
            path: '/administrador/proveedor-lote', title: 'Proveedor lote', type: 'link',
            hidden: !(this.permisos.administrador && this.permisos.administrador.proveedorLote),
          },
          {
            path: '/administrador/accesos', title: 'Accesos', type: 'link',
            hidden: !(this.permisos.administrador && this.permisos.administrador.accesos),
          },
          {
            path: '/administrador/ver-tareas', title: 'Ver Tareas', type: 'link',
            hidden: !(this.permisos.administrador && this.permisos.administrador.tareas),
          },
          {
            title: 'Aprobar ordenes',
            type: 'sub',
            hidden: !(this.permisos.administrador && this.permisos.administrador.aprobarOrdenes),
            children: [
              {
                path: '/administrador/aprobar-ordenes/ecommerce', title: 'Ecommerce', type: 'link',
                hidden: !(this.permisos.administrador && this.permisos.administrador.aprobarOrdenesEcommerce),
              },
              {
                path: '/administrador/aprobar-ordenes/aplicacion', title: 'Aplicación', type: 'link',
                hidden: !(this.permisos.administrador && this.permisos.administrador.aprobarOrdenesAplicacion),
              },
            ]
          },
          {
            path: '/administrador/configurar-metas',
            title: 'Configuración de metas',
            type: 'link',
            hidden: !(this.permisos.administrador)
          },
          {
            path: '/administrador/tickets', title: 'Tickets', type: 'link',
            hidden: !(this.permisos.administrador),
          }
        ]
      },
      {
        title: 'Supplier', icon: 'truck', type: 'sub', hidden: !this.permisos.proveedor, children: [
          {
            path: '/supplier/admin', title: 'Pending products', type: 'link',
            hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
          },
          {
            path: '/supplier/rejected-products', title: 'Rejected Products', type: 'link', hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
          },
          {
            path: '/supplier/accepted-products', title: 'Accepted Products', type: 'link', hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
          },
          {
            path: '/supplier/history-returns-clarification',
            title: 'History of returns to clarification',
            type: 'link',
            hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
          }
          /*          {
                      path: '/proveedor/administrador', title: 'Administrador', type: 'link',
                      hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
                    },*/
          /*{
            path: '/proveedor/administrador', title: 'Lot', type: 'link',
            hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
          },
          {
            path: '/proveedor/archivo-proveedor', title: 'Send file', type: 'link',
            // hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
          },
          {
            path: '/proveedor/concesiones-productos', title: 'Solicitudes concesión', type: 'link',
            // hidden: !(this.permisos.proveedor && this.permisos.proveedor.administrador)
          },*/
        ]
      },
      {
        title: 'Entradas/Salidas',
        icon: 'search',
        type: 'sub',
        hidden: !(this.permisos['entradas-salidas'] || this.permisos.proveedor),
        children: [
          {
            path: '/verificacion-producto/llegada-producto', title: 'Productos pendientes por llegar', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.historicoAclaraciones || this.permisos['verificacion']?.historicoAclaraciones || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/salida-producto', title: 'Salida producto', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.salidaProductos || this.permisos['verificacion']?.salidaProductos || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/salida-producto-historico', title: 'Histórico de Salida de producto', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.historicoSalidaProducto || this.permisos['verificacion']?.historicoSalidaProducto || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/historico-llegadas-correctas', title: 'Historico de llegadas correctas', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.historicoLlegadasCorrectas || this.permisos['verificacion']?.historicoLlegadasCorrectas || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/historico-llegadas-incorrectas', title: 'Historico de llegadas incorrectas', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.historicoLlegadasIncorrectas || this.permisos['verificacion']?.historicoLlegadasIncorrectas || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/historico-aclaraciones',
            title: 'Historico de aclaraciones',
            type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.historicoAclaraciones || this.permisos['verificacion']?.historicoAclaraciones || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/guia-manual-nn-minutes', title: 'Guía manual 99 minutos', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.NNMinutes || this.permisos['verificacion']?.NNMinutes || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/guias-subidas', title: 'Guías subidas', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.salidaProductos || this.permisos['verificacion']?.salidaProductos || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/enviados-cliente', title: 'Productos envíados por cliente', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.salidaProductos || this.permisos['verificacion']?.salidaProductos || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          },
          {
            path: '/verificacion-producto/enviados-cliente-history', title: 'Historial de productos envíados por cliente', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.salidaProductos || this.permisos['verificacion']?.salidaProductos || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          }
        ]
      },
      {
        title: 'Guías',
        icon: 'truck',
        type: 'sub',
        hidden: !(this.permisos['entradas-salidas'] || this.permisos.proveedor),
        children: [
          {
            path: '/guides/productos-listos-devolucion', title: 'Productos para devolver', type: 'link',
            hidden: !(this.permisos['entradas-salidas']?.historicoAclaraciones || this.permisos['verificacion']?.historicoAclaraciones || this.permisos['verificacion']?.administrador || this.permisos['proveedor']?.administrador)
          }
        ]
      },
      {
        title: 'Cownection Center',
        icon: 'truck',
        type: 'sub',
        hidden: !(this.permisos.administrador),
        children: [
          {
            path: '/cownection-center/faqs', title: 'Preguntas frecuentes', type: 'link',
            hidden: !(this.permisos.administrador)
          },
          {
            path: '/cownection-center/inactivity', title: 'Inactivity', type: 'link',
            hidden: !(this.permisos.administrador)
          },
          {
            path: '/cownection-center/promps', title: 'Promps', type: 'link',
            hidden: !(this.permisos.administrador)
          },

        ]
      }
      /*{
        title: 'Catálogos', icon: 'book-open', type: 'sub', hidden: !this.permisos.catalogos, children: [
          {
            path: '/catalogos/paqueterias', title: 'Paqueterias', type: 'link',
            hidden: !(this.permisos.catalogos && this.permisos.catalogos.paqueterias),
          },
          {
            path: '/catalogos/distribuidores', title: 'Distribuidores', type: 'link',
            hidden: !(this.permisos.catalogos && this.permisos.catalogos.distribuidores)
          },
          {
            path: '/catalogos/estado-factura', title: 'Estado factura', type: 'link',
            hidden: !(this.permisos.catalogos && this.permisos.catalogos.estadoFactura)
          },
          {
            path: '/catalogos/tipo-aclaracion', title: 'Tipo aclaración', type: 'link',
            hidden: !(this.permisos.catalogos && this.permisos.catalogos.tipoAclaracion)
          },
          {
            path: '/catalogos/estado-aclaracion', title: 'Estado aclaración', type: 'link',
            hidden: !(this.permisos.catalogos && this.permisos.catalogos.estadoAclaracion)
          },
          /!*{path: '/catalogos/tipo-descuento', title: 'Tipo descuento', type: 'link',
            hidden: !(this.permisos.catalogos && this.permisos.catalogos.tipoDescuento)},*!/
        ]
      },*/
      /*     {
             path: '/tpv/inicio',
             title: 'Tpv',
             icon: 'home',
             type: 'link',
             hidden: false,
             badgeType: 'primary',
           },*/
    ];
    // Array
    this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  }

  public screenWidth: any;
  public collapseSidebar = false;

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  private getPermission(module: string) {
    const permission = this.permisos[module];



  }


}
