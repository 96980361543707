import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivateChild {
  constructor(
    private login: LoginService,
    private notificaciones: NotificacionesService,
    private router: Router
  ) {}

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.login.validToken()) {
      this.notificaciones.error('Tu sesión ha expirado, serás redirigido al login').then(
        () => {
          sessionStorage.clear();
          this.router.navigate(['/']);
        }
      );
      return false;
    }
    return true;
  }
}
