import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {TareasServiceService} from '../../../core/services/tareas-service.service';
import * as jwt_decode from 'jwt-decode';
import {NotificacionesService} from '../../../core/services/notificaciones.service';

@Component({
  selector: 'app-modal-agregar-tarea',
  templateUrl: './modal-agregar-tarea.component.html',
  styleUrls: ['./modal-agregar-tarea.component.scss']
})
export class ModalAgregarTareaComponent implements OnInit {
  agregarTareasForm: FormGroup = new FormGroup({
    idTopicosCatalogo: new FormControl('', Validators.required),
    funcion: new FormControl('', Validators.required),
    comentarios: new FormControl(null),
    idUsuario: new FormControl(jwt_decode(sessionStorage.getItem('token')).sub),
  });
  catalogoTopico: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalAgregarTareaComponent>,
    public tareasService: TareasServiceService,
    public notificacionesService: NotificacionesService,
  ) { }

  ngOnInit(): void {
    this.getCatalogoTopicos();
  }

  getCatalogoTopicos() {
    this.tareasService.getCatalogoTopicos().subscribe({
      next: value => this.catalogoTopico = value,
      error: err => console.log(err),
    });
  }

  agregarTarea() {
    this.notificacionesService.carga('Guardando datos');
    this.tareasService.agregarTarea(this.agregarTareasForm.value).subscribe({
      error: err => {
        this.notificacionesService.error('Ha ocurrido un error, vuelve a intentarlo más tarde');
        console.log(err);
      },
      complete: () => this.notificacionesService.exito('Tarea agregada').then(() => this.dialogRef.close()),
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
