import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'productos',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: 'Productos'
    }
  },
  {
    path: 'clientes',
    loadChildren: () => import('../../components/clientes/clientes.module').then(m => m.ClientesModule),
    data: {
      breadcrumb: 'Clientes'
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: 'Ventas'
    }
  },
  {
    path: 'reportes',
    loadChildren: () => import('../../components/reportes/reportes.module')
      .then( m => m.ReportesModule),
    data: {
      breadcrumb: 'Reportes'
    }
  },
  {
    path: 'recursos-humanos',
    loadChildren: () => import('../../components/recursos-humanos/recursos-humanos.module').then(m => m.RecursosHumanosModule),
    data: {
      breadcrumb: 'Recursos humanos'
    }
  },
  {
    path: 'cupones',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: 'Cupones'
    }
  },
  {
    path: 'discounts',
    loadChildren: () => import('../../components/discounts/discounts.module').then(m => m.DiscountsModule),
    data: {
      breadcrumb: 'Descuento'
    }
  },
  {
    path: 'benefits',
    loadChildren: () => import('../../components/benefits/benefits.module').then(m => m.BenefitsModule),
    data: {
      breadcrumb: 'Benefits'
    }
  },
  {
    path: 'financial',
    loadChildren: () => import('../../components/financial/financial.module').then(m => m.FinancialModule),
    data: {
      breadcrumb: 'Financial'
    }
  },
  {
    path: 'atencion-clientes',
    loadChildren: () => import('../../components/atencion-a-clientes/atencion-a-clientes.module').then(m => m.AtencionAClientesModule),
    data: {
      breadcrumb: 'Atención a clientes'
    }
  },
  {
    path: 'catalogos',
    loadChildren: () => import('../../components/catalogos/catalogos.module').then(m => m.CatalogosModule),
    data: {
      breadcrumb: 'Catálogos'
    }
  },
  {
    path: 'usuarios',
    loadChildren: () => import('../../components/usuarios/usuarios.module').then(m => m.UsuariosModule),
    data: {
      breadcrumb: 'Usuarios'
    }
  },
  {
    path: 'finanzas',
    loadChildren: () => import('../../components/finanzas/finanzas.module').then(m => m.FinanzasModule),
    data: {
      breadcrumb: 'Finanzas'
    }
  },
  {
    path: 'distribuidores',
    loadChildren: () => import('../../components/distribuidores/distribuidores.module').then(m => m.DistribuidoresModule),
    data: {
      breadcrumb: 'Distribuidores'
    }
  },
  {
    path: 'vendedor-externo',
    loadChildren: () => import('../../components/vendedor-externo/vendedor-externo.module').then(m => m.VendedorExternoModule),
    data: {
      breadcrumb: 'Vendedor Externo'
    }
  },
  {
    path: 'lotes',
    loadChildren: () => import('../../components/lotes/lotes.module').then(m => m.LotesModule),
    data: {
      breadcrumb: 'Lotes'
    }
  },
  {
    path: 'cobranza',
    loadChildren: () => import('../../components/cobranza/cobranza.module').then(m => m.CobranzaModule),
    data: {
      breadcrumb: 'Cobranza'
    }
  },
  {
    path: 'marketing',
    loadChildren: () => import('../../components/marketing/marketing.module').then(m => m.MarketingModule),
    data: {
      breadcrumb: 'Marketing'
    }
  },
  {
    path: 'ecommerce',
    loadChildren: () => import('../../components/ecommerce/ecommerce.module').then(m => m.EcommerceModule),
    data: {
      breadcrumb: 'Ecommerce'
    }
  },
  {
    path: 'administrador',
    loadChildren: () => import('../../components/administrador/administrador.module').then(
      m => m.AdministradorModule
    ),
    data: {
      breadcrumb: 'Administrador'
    }
  },
  {
    path: 'supplier',
    loadChildren: () => import('../../components/proveedor/proveedor.module').then(
      m => m.ProveedorModule
    ),
    data: {
      breadcrumb: 'Supplier'
    }
  },
  {
    path: 'usuario',
    loadChildren: () => import('../../components/usuario/usuario.module').then(m => m.UsuarioModule),
    data: {
      breadcrumb: 'Usuario'
    }
  },
  {
    path: 'verificacion-producto',
    loadChildren: () => import(
      '../../components/verificacion-producto/verificacion-producto.module'
    ).then(m => m.VerificacionProductoModule),
    data: {
      breadcrumb: 'Verificación'
    }
  },
  {
    path: 'guides',
    loadChildren: () => import('../../components/guides/guides.module').then(m => m.GuidesModule),
    data: {
      breadcrumb: 'Guias'
    }
  },
  {
    path: 'cownection-center',
    loadChildren: () => import('../../components/cownection-center/cownection-center.module').then(m => m.CownectionCenterModule),
    data: {
      breadcrumb: 'Cownection Center'
    }
  }
];
