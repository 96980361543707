import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsuariosService } from 'src/app/core/services/clientes/usuarios.service';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';
import { ProductoService } from 'src/app/core/services/productos/producto.service';
import { Notificaciones } from 'src/app/notificaciones';
import { threadId } from 'worker_threads';
@Component({
  selector: 'app-modal-producto-cliente',
  templateUrl: './modal-producto-cliente.component.html',
  styleUrls: ['./modal-producto-cliente.component.scss']
})
export class ModalProductoClienteComponent implements OnInit {
  loadingUsers = false;
  tallas: { id: number, talla: string }[] = [];
  usuarios: any[];
  form: FormGroup;
  filteredUsers: any[] = [];
  idEmpresaProducto: number;


  constructor(
    private dialogRef: MatDialogRef<ModalProductoClienteComponent>,
    private productoService: ProductoService,
    private usuariosService: UsuariosService,
    private notificaciones: Notificaciones,
    private notificacion : NotificacionesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.loadingUsers = true;
    this.getUsuarios();
    this.getTallasById();
    console.log(this.data)
    this.form = new FormBuilder().group({
      talla: new FormControl(null, Validators.required),
      correo: new FormControl('', [Validators.required, Validators.email]),
      cantidad: new FormControl(1, [Validators.required, Validators.min(1)]), 
      apartado: new FormControl(false), 
      montoApartado: new FormControl({value: null}) 
    });    
  }

  getTallasById() {
    this.idEmpresaProducto = this.data.idProductoEmpresa;
    this.productoService.getTallasById(this.idEmpresaProducto)
      .subscribe((data) => {
        this.tallas = data;
      });
  }

  getUsuarios() {
    this.usuariosService.getUsuarios()
      .subscribe(
        (data) => {
          this.usuarios = data;
          console.log(data)
          console.log('Usuarios cargados correctamente:');
        },
        (error) => {
          console.error('Error al cargar usuarios:', error);
        }
      );
  }

  onKeyUp() {
    const searchText = this.form.get('correo').value;
    if (searchText && searchText.length > 2) {
      this.filteredUsers = this.usuarios.filter(usuario =>
        (usuario.nombre && usuario.nombre.toLowerCase().includes(searchText.toLowerCase())) ||
        (usuario.email && usuario.email.toLowerCase().includes(searchText.toLowerCase()))
      );
      const userExists = this.filteredUsers.some(usuario => usuario.email === searchText);
      if (!userExists) {
        this.form.get('correo').setErrors({ 'emailNotFound': true });
      }
    } else {
      this.filteredUsers = [];
      this.form.get('correo').setErrors(null); 
    }
  }
  

  displayFn(user: any): string {
    return user ? `${user.nombre} - ${user.email}` : '';
  }

  selectUser(user: any) {
    this.form.get('correo').setValue(user.email); 
    this.form.get('correo').setValidators(null); 
    this.form.get('correo').updateValueAndValidity(); 
    this.form.addControl('idUsuario', new FormControl(user.id));
    this.filteredUsers = [];
  }

  salir() {
    const idEmpresaProductoTalla = this.form.get('talla').value;
    const idUsuario = this.form.get('idUsuario').value; 
    const cantidad = this.form.get('cantidad').value;
    const apartado = this.form.get('apartado').value;
    let montoApartado = 0;
    if (apartado) {
      montoApartado = this.data.precioVenta * 0.35;
      console.log('Monto de Apartado:', montoApartado);
    }
    this.notificacion.confirmacion("Desea agregar al carrtito",false).then((respon)=>{
      if(respon.value==true){
        this.productoService.addKart({ idEmpresaProductoTalla,fromStock: false, idUsuario, cantidad, apartado, montoApartado })
      .subscribe(() => {
        this.notificaciones.exito("Producto agregado al carrito");
        this.dialogRef.close()
      });
      }else{
        this.dialogRef.close();
      }
    })

   
    
  }
}
