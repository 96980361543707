<mat-card>
  <!-- Carga al seleccionar empresa -->
  <div class="row justify-content-center" *ngIf="!empresas || (idEmpresa && !productos); else contenido">
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #contenido>
    <!-- Selectores -->
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Empresa</mat-label>
          <mat-select required [(ngModel)]="idEmpresa" (selectionChange)="getProductos()" style="text-align: center" [disabled]="idEmpresa">
            <mat-option *ngFor="let empresa of empresas" [value]="empresa.id">{{empresa.empresa}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div class="col-2">
        <mat-form-field>
          <mat-label>Marcas</mat-label>
          <mat-select [(ngModel)]="idMarca" (selectionChange)="getMarcas()" style="text-align: center">
            <mat-option *ngFor="let marca of marcas" [value]="marca.id">{{marca.descripcion}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>

    <!-- Input de busqueda -->
    <div class="row justify-content-center" *ngIf="idEmpresa">
      <div class="col-12">
        <mat-form-field>
          <input matInput placeholder="Producto" aria-label="Producto" [matAutocomplete]="auto"
                 [formControl]="buscador" oninput="this.value = this.value.toUpperCase()">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let producto of productosFiltrados | async" [value]="producto.nombre"
                        (click)="agregarProducto(producto)">
              <img aria-hidden [src]="producto.imagen" height="25" loading="lazy">
              <span>{{producto.nombre}}</span> |
              <small [ngClass]="producto.idTipoDescuento ? 'tachado': null">
                {{producto.precioVenta | currency : 'MXN'}}
              </small>
              <small *ngIf="producto.idTipoDescuento" style="margin-left: 5px">
                {{(
                producto.idTipoDescuento === 1 ?
                  (producto.precioVenta - ((producto.precioVenta * producto.descuento) / 100)) :
                  (producto.precioVenta - producto.descuento)
              ) | currency : 'MXN'}}
              </small>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <!-- Resultados -->
    <div class="row justify-content-center" *ngIf="idEmpresa">
      <div class="col-12">
        <table mat-table [dataSource]="productosSeleccionados" class="mat-elevation-z8"
               *ngIf="productosSeleccionados.data.length > 0">
          <ng-container matColumnDef="imagen">
            <th mat-header-cell *matHeaderCellDef> Imagen</th>
            <td mat-cell *matCellDef="let transaction">
              <img [src]="transaction.imagen" height="100"/>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.nombre}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="precio">
            <th mat-header-cell *matHeaderCellDef> Precio</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.precioVenta | currency: 'MXN'}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="descuento">
            <th mat-header-cell *matHeaderCellDef>Descuento</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.descuento | currency: 'MXN'}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="precioDescuento">
            <th mat-header-cell *matHeaderCellDef>Descuento Aplicado</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.descuento | currency: 'MXN'}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef>Descripcion</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.descripcion}} </td>
            <td mat-footer-cell *matFooterCellDef >
              <button mat-raised-button  color="primary"  style="margin-right: 8px;" (click)="abrirPopupVenta()" >
                <span>Producto a cliente</span>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="marca">
            <th mat-header-cell *matHeaderCellDef>Marca</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.descripcionMarca}} </td>
            <td mat-footer-cell *matFooterCellDef>
              <button mat-raised-button color="primary" (click)="compartirUrl()" class="button">
                <span>Compartir</span>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="submarca">
            <th mat-header-cell *matHeaderCellDef>Submarca</th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.descripcionSubMarcas}} </td>
            <td mat-footer-cell *matFooterCellDef>
              <button mat-raised-button color="primary" (click)="generarCotizacion()" class="button">
                <span>Generar cotización</span>
              </button>             
            </td>
            
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns">
            
          </tr>
          
        </table>
        
      </div>
    </div>
  </ng-template>
</mat-card>