import Swal from 'sweetalert2';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Notificaciones {

  constructor() {}

  carga(texto: string): Promise<any> {
    return Swal.fire({
      title: texto + ', por favor espere',
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  exito(texto: string): Promise<any> {
    return Swal.fire({
      type: 'success',
      title: 'Correcto',
      text: '¡' + texto + ' con éxito!',
    });
  }

  informacion(texto: string): Promise<any> {
    return Swal.fire({
      type: 'info',
      title: 'Información',
      text: texto,
    });
  }

  error(texto?: string): Promise<any> {
    return Swal.fire({
      title: 'Error',
      text: texto ? texto : 'Ha ocurrido un error, vuelve a intentarlo más tarde',
      type: 'error'
    });
  }

  advertencia(texto: string): Promise<any> {
    return Swal.fire({
      title: 'Advertencia',
      text: texto,
      type: 'warning'
    });
  }

}
