import { Injectable } from '@angular/core';
import Swal, { SweetAlertType } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor() { }

  carga(texto: string, ingles?: boolean): Promise<any> {
    return Swal.fire({
      title: texto + (ingles ? ' please wait' : ', por favor espere'),
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    });
  }

  exito(texto: string): Promise<any> {
    return Swal.fire({
      type: 'success',
      title: 'Correcto',
      text: '¡' + texto + ' con éxito!',
    });
  }

  exitoEnglish(texto: string): Promise<any> {
    return Swal.fire({
      type: 'success',
      title: 'Success',
      text: '¡' + texto + ' successfully!',
    });
  }

  exitoPersonalizado(html: any, title?: string): Promise<any> {
    return Swal.fire({
      type: 'success',
      title: title ? title : 'Correcto',
      html,
    });
  }

  informacion(texto: string, titulo?: string): Promise<any> {
    return Swal.fire({
      type: 'info',
      title: titulo ? titulo : 'Información',
      text: texto,
    });
  }

  error(texto?: string): Promise<any> {
    return Swal.fire({
      title: 'Error',
      text: texto ? texto : 'Ha ocurrido un error, vuelve a intentarlo más tarde',
      type: 'error'
    });
  }

  advertencia(texto: string, titulo?: string): Promise<any> {
    return Swal.fire({
      title: titulo ? titulo : 'Advertencia',
      text: texto,
      type: 'warning'
    });
  }

  confirmacion(texto: string, ingles?: boolean): Promise<any> {
    return Swal.fire({
      title: ingles ? 'Confirm' : 'Confirmación',
      html: texto,
      type: 'question',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: 'red',
      confirmButtonColor: '#0D9743',
      cancelButtonText: ingles ? 'Cancel' : 'Cancelar',
      confirmButtonText: ingles ? 'Continue' : 'Continuar'
    });
  }

  confirmacionCustomizable({
    texto,
    title = null,
    ingles = false,
    confirmButtonColor = '#0D9743',
    cancelButtonColor = 'red',
    type = "question"
  }: {
    texto: string,
    ingles?: boolean,
    confirmButtonColor?: string,
    cancelButtonColor?: string,
    title?: string,
    type?: SweetAlertType
  } = {} as any): Promise<any> {
    return Swal.fire({
      title: title ?? (ingles ? 'Confirm' : 'Confirmación'),
      html: texto,
      type,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor,
      confirmButtonColor,
      cancelButtonText: ingles ? 'Cancel' : 'Cancelar',
      confirmButtonText: ingles ? 'Continue' : 'Continuar'
    });
  }

  cerrar() {
    return Swal.close();
  }

  choseBetweenTwo(): Promise<any> {
    return Swal.fire({
      title: 'Confirmación',
      html: 'Elige el método de pago',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D9743',
      cancelButtonColor: '#0D9743',
      confirmButtonText: 'Pago en efectivo o transferencia',
      cancelButtonText: 'Pago con tarjeta de crédito o débito',
    });
  }

  choseBetweenTwoGuide(): Promise<any> {
    return Swal.fire({
      title: 'Confirmación',
      html: 'Tipo de envío',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D9743',
      cancelButtonColor: '#0D9743',
      confirmButtonText: 'Enviar todos los productos de la orden',
      cancelButtonText: 'Enviar solo este producto',
    });
  }
}
