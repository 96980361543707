import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {

  constructor() { }

  public users = [
    {
      name: 'Vincent Porter',
      status: 'Online'
    },
    {
      name: 'Ain Chavez',
      status: '28 minutes ago'
    },
    {
      name: 'Kori Thomas',
      status: 'Online'
    },
    {
      name: 'Erica Hughes',
      status: 'Online'
    },
    {
      name: 'Ginger Johnston',
      status: '2 minutes ago'
    },
    {
      name: 'Prasanth Anand',
      status: '2 hour ago'
    },
    {
      name: 'Hileri Jecno',
      status: 'Online'
    }
  ];

  ngOnInit() { }

}
