import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TareasServiceService {
  private url;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl;
  }

  getTarea(id) {
    return this.http.get<any>(this.url + `tarea/${id}`);
  }

  getCatalogoTopicos() {
    return this.http.get<any[]>(this.url + `tareas/topicos`);
  }

  getTareasView() {
    return this.http.get<any[]>(this.url + `tareas-view`);
  }

  getTareasByUsuario() {
    return this.http.get<any[]>(this.url + `tareas-usuario/${jwt_decode(sessionStorage.getItem('token')).sub}`);
  }

  agregarTarea(data) {
    return this.http.post<any>(this.url + `tareas/agregar-tarea`, data);
  }

  responderTarea(id, data) {
    return this.http.put<any>(this.url + `responder/tarea/${id}`, data);
  }

  finalizarTarea(datosTarea) {
    return this.http.put<any>(this.url + `tareas/concluir-tarea`, datosTarea);
  }
}
