<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body">
              <ngb-tabset class="tab-coupon mb-0">
                <ngb-tab>
                  <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Iniciar sesión</ng-template>
                  <ng-template ngbTabContent>
                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                         aria-labelledby="account-tab">
                      <form [formGroup]="loginForm"
                            class="form-horizontal auth-form" novalidate="">
                        <div class="form-group">
                          <input required="" formControlName="email" type="email"
                                 class="form-control" placeholder="Usuario"
                                 id="exampleInputEmail1">
                        </div>
                        <div class="form-group">
                          <input required="" formControlName="password" type="password"
                                 class="form-control" placeholder="Contraseña">
                        </div>
                        <div class="form-terms">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <a href="javascript:void(0)"
                               class="btn btn-default forgot-pass">Olvidé mi contraseña</a>
                          </div>
                        </div>
                        <div class="form-button" style="text-align: center">
                          <button class="btn btn-primary" (click)="login()" type="submit" *ngIf="!inicioEnProceso else cargando">
                            Iniciar sesión
                          </button>
                          <ng-template #cargando>
                            <mat-progress-bar [color]="'warn'" mode="indeterminate" style="margin-top: 3%"></mat-progress-bar>
                          </ng-template>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
