import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent} from './dashboard.component';
import {ChartsModule} from 'ng2-charts';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {ChartistModule} from 'ng-chartist';
import {SharedModule} from '../../shared/shared.module';
import {AuthGuardService} from '../../core/services/auth-guard.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import {getSpanishPaginatorIntl} from '../../traduccion-paginator';
import { UltimosPedidosComponent } from './componentes/ultimos-pedidos/ultimos-pedidos.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DashboardComponent, UltimosPedidosComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    ChartistModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  providers: [
    AuthGuardService,
    {provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl()},
  ]
})
export class DashboardModule {
}
