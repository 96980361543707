import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FeatherIconsComponent} from './components/feather-icons/feather-icons.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {ToggleFullscreenDirective} from './directives/fullscreen.directive';
import {ContentLayoutComponent} from './layout/content-layout/content-layout.component';
import {NavService} from './service/nav.service';
import {WINDOW_PROVIDERS} from './service/windows.service';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {RightSidebarComponent} from './components/right-sidebar/right-sidebar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ModalAgregarTareaComponent} from './components/modal-agregar-tarea/modal-agregar-tarea.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {registerLocaleData} from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';
import { ModalBuscadorComponent } from './components/modal-buscador/modal-buscador.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { ModalProductoClienteComponent } from './components/modal-producto-cliente/modal-producto-cliente.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AsistenteComponent } from './components/asistente/asistente.component';

registerLocaleData(localeMx);

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    ModalAgregarTareaComponent,
    ModalBuscadorComponent,
    ModalProductoClienteComponent,
    AsistenteComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatTableModule,
    MatCheckboxModule,
  ],
  providers: [
    NavService,
    WINDOW_PROVIDERS,
    {provide: LOCALE_ID, useValue: 'es-MX'}
  ],
  exports: [FeatherIconsComponent, ToggleFullscreenDirective],
  entryComponents: [
    ModalAgregarTareaComponent,
    ModalBuscadorComponent
  ]
})
export class SharedModule {
}
