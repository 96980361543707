<form class="example-form" [formGroup]="mainForm" style="position: sticky; top: 0;">
    <div class="messageBox">
      <input required="" placeholder="En que puedo ayudarte" type="text" id="messageInput" formControlName="pregunta" style="width: 100%;"/>
      <button id="sendButton" (click)="respuesta()">
        <span *ngIf="cargandoRespuesta; else showMessage">
          <mat-spinner 
          color="primary"
          diameter="20"
          >
          </mat-spinner>
        </span>
        <ng-template #showMessage>
          <mat-icon>send</mat-icon>
        </ng-template>
      </button>
    </div>
</form>

<div *ngIf="true" class="responses-container">
  <div *ngFor="let res of responses">
    <p style="margin-bottom: 10px;"><span><b>{{res.title}}</b></span><br></p>
    <span>{{res.respuesta}}</span><br>
    <hr>
  </div>
</div>