import {Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrdenesService } from 'src/app/core/services/ordenes/ordenes.service';

@Component({
  selector: 'app-ultimos-pedidos',
  templateUrl: './ultimos-pedidos.component.html',
  styleUrls: ['./ultimos-pedidos.component.scss']
})
export class UltimosPedidosComponent implements OnInit {
  displayedColumns: string[] = ['fecha', 'nombre', 'telefono', 'email', 'total', 'acciones'];
  ordenes: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private ordenesService: OrdenesService, private router: Router) { }

  ngOnInit() {
    this.getOrdenes();
  }

  navigateToDetails(orden: string) {
    this.router.navigate(['/sales/ordenes'], { queryParams: { orden: orden.toUpperCase() } });
  }

  getOrdenes() {
    this.ordenesService.getLastOrders().subscribe(data => {
      console.log(data);
      
      
      this.ordenes = new MatTableDataSource(data.filter(i => i.isVisible == "1"));
      this.ordenes.sort = this.sort;
      this.ordenes.paginator = this.paginator;
    });
  }

}
