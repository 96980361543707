import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = 'https://backend-shoefiti.desarrollatech.com/api/mercado-pago';
  }

  put() {
    return this.http.put(this.url, null);
  }
}
