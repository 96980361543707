<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="permisos.dashboard && permisos.dashboard.reportes; else sinPermisos">
  <div class="row">
    <div class="col-12">
      <mat-form-field>
        <mat-label>Empresa</mat-label>
        <mat-select [formControl]="empresaSeleccionada" (selectionChange)="getCantidades()">
          <mat-option *ngFor="let dato of datosSelector" [value]="dato.id">
            {{dato.empresa}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-spinner *ngIf="!cantidades" color="warn"></mat-spinner>
  <div class="row" *ngIf="cantidades">
    <div class="col-xl-3 col-md-6 xl-50">
      <div class="card o-hidden widget-cards">
        <div class="bg-warning card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons [icon]="'dollar-sign'" class="font-warning"></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">Ventas totales</span>
              <h3 class="mb-0" *ngIf="cantidades.cantidadTotal; else elseCantidad">
                $ {{cantidades.cantidadTotal | number:'1.2-2'}}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 xl-50">
      <div class="card o-hidden widget-cards">
        <div class="bg-primary card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons [icon]="'dollar-sign'" class="font-primary"></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">Ventas mensuales</span>
              <h3 class="mb-0" *ngIf="cantidades.cantidadMensual; else elseCantidad">
                $ {{cantidades.cantidadMensual | number:'1.2-2'}}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 xl-50">
      <div class="card o-hidden widget-cards">
        <div class="bg-danger card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">Ventas semanales</span>
              <h3 class="mb-0" *ngIf="cantidades.cantidadSemanal; else elseCantidad">
                $ {{cantidades.cantidadSemanal | number:'1.2-2'}}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 xl-50">
      <div class="card o-hidden  widget-cards">
        <div class="bg-secondary card-body">
          <div class="media static-top-widget row">
            <div class="icons-widgets col-4">
              <div class="align-self-center text-center">
                <app-feather-icons [icon]="'dollar-sign'" class="font-secondary"></app-feather-icons>
              </div>
            </div>
            <div class="media-body col-8"><span class="m-0">Ventas de hoy</span>
              <h3 class="mb-0" *ngIf="cantidades.cantidadHoy; else elseCantidad">
                $ {{cantidades.cantidadHoy | number:'1.2-2'}}
              </h3>
              <ng-template #elseCantidad>
                <h3 class="mb-0">
                  $ 0.00
                </h3>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="empresaSeleccionada.value !== 8; else logoShoefiti">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h5>Últimos Pedidos</h5>
        </div>
        <div class="card-body">
          <div class="user-status table-responsive latest-order-table">
            <app-ultimos-pedidos></app-ultimos-pedidos>
            <a [routerLink]="['/sales/ordenes']" class="btn btn-primary">Todos Los Pedidos</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #sinPermisos>
  <mat-card-title>
    ¡Buen día {{nombre}}, bienvenido al backoffice! <br/><br/>
  </mat-card-title>
  <div style="text-align: center; margin-top: 5%;">
    <a href="https://sneakerstore.com.mx/" target="_blank" *ngIf="empresaSeleccionada.value !== 8; logoShoefiti">
      <img src="https://imagenes-sneakers.s3.us-east-2.amazonaws.com/logos/sneakers.png"/>
    </a>
    <ng-template #logoShoefiti>
      <a href="https://shoefiti.com.mx" target="_blank">
        <img src="https://imagenes-sneakers.s3.us-east-2.amazonaws.com/logos/LOGO_SHOEFITI_T_N_CORREO.png"/>
      </a>
    </ng-template>
  </div>
</ng-template>
<!-- Container-fluid Ends-->
