import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  url: any;
  urlNuevaBase: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl + 'productos';
    this.urlNuevaBase = environment.apiURLNuevaBase + 'productos';
  }

  getProductos(): Observable<any[]> {
    return this.http.get<any[]>(this.url);
  }

  getProductosNew(): Observable<any[]> {
    return this.http.get<any[]>(this.urlNuevaBase);
  }

  getById(id): Observable<any> {
    return this.http.get<any>(this.urlNuevaBase + '/by-id/' + id);
  }

  getEmpresasActivas(id: number): Observable<any> {
    return this.http.get(this.urlNuevaBase + '/empresas-activas/' + id);
  }

  getMarcasActivas(id: number): Observable<any> {
    return this.http.get(this.urlNuevaBase + '/marcas-activas/' + id);
  }

  getSubMarcasActivasByMarca(idProducto: number, idMarca: number): Observable<any> {
    return this.http.get(this.urlNuevaBase + '/submarcas-activas/' + idProducto + '/marca/' + idMarca);
  }

  getSubMarcasActivas(id: number): Observable<any> {
    return this.http.get(this.urlNuevaBase + '/submarcas-activas/' + id);
  }

  getCategoriasActivas(id: number): Observable<any> {
    return this.http.get(this.urlNuevaBase + '/categorias-activas/' + id);
  }

  getTallasActivas(id: number, idEmpresa: number): Observable<any> {
    return this.http.get(this.urlNuevaBase + '/tallas-activas/' + id + '/' + idEmpresa);
  }

  getImagenesActivas(idProducto: number) {
    return this.http.get(this.urlNuevaBase + '/imagenes-activas/' + idProducto);
  }

  desactivarProducto(id: number, idEstadoProducto: number): Observable<any> {
    return this.http.put(`${this.urlNuevaBase}/activar-desactivar-producto/${id}/${idEstadoProducto}`, null);
  }

  update(producto): Observable<any> {
    return this.http.put<any>(this.urlNuevaBase, producto);
  }

  getProductosOutlet(): Observable<any[]> {
    return this.http.get<any[]>(this.url + '-on-outlet');
  }

  post(producto): Observable<any> {
    return this.http.post<any>(this.url, producto);
  }

  saveOutlet(data): Observable<any> {
    return this.http.post<any>(this.url + '-outlet', data);
  }

  bajaProducto(id): Observable<any> {
    return this.http.get<any>(this.url + '/baja/' + id);
  }

  altaProducto(id): Observable<any> {
    return this.http.get<any>(this.url + '/alta/' + id);
  }

  updatePrecioNeto(id, producto): Observable<any> {
    return this.http.put<any>(this.url + '/modificar-precio-neto/' + id, producto);
  }

  getProductosInterno(): Observable<any> {
    return this.http.get<any>(this.url + '/productos-interno');
  }

  getProductosSS(): Observable<any> {
    return this.http.get<any>(this.url + '/productos-ss');
  }

  getStockFisicoTotal(): Observable<any[]> {
    return this.http.get<any[]>(this.url + '/test/stock-fisico-total');
  }

  desactivarProductoOutlet(id): Observable<any> {
    return this.http.put<any>(this.url + `-outlet/desactivar/${id}`, null);
  }

  addEntregaInmediata(datosProducto) {
    return this.http.post(this.url + '/entrega-inmediata', datosProducto);
  }

  getProductoEntregaInmediata(): Observable<any> {
    return this.http.get(this.url + '/entrega-inmediata');
  }

  getDatosById(id: number): Observable<any> {
    return this.http.get(`${this.urlNuevaBase}/datos/${id}`);
  }

  getProductosMerchantCenter(idEmpresa: number): Observable<any> {
    return this.http.get(`${this.urlNuevaBase}/merchant-center/${idEmpresa}`);
  }

  getTallasEntregaInmediata(idProducto: number, idEmpresa: number): Observable<any> {
    return this.http.get(`${this.urlNuevaBase}/tallas-entrega-inmediata/${idProducto}/${idEmpresa}`);
  }

  inStock(data): Observable<any> {
    return this.http.post<any>(this.urlNuevaBase + '/update-product-in-stock', data);
  }

  getSitemap(): Observable<any> {
    return this.http.get<any>(`${this.urlNuevaBase}/`);
  }

  getSugerencias(): Observable<any> {
    return this.http.get(`${this.urlNuevaBase}/sugerencias`);
  }

  eliminarSugerencia(body): Observable<any> {
    return this.http.post(`${this.urlNuevaBase}/eliminar-sugerencia`, body);
  }

  cambiarEstadoSugg(body) {
    return this.http.post(`${this.urlNuevaBase}/actualizar-sugerencia`, body);
  }

  addProductoHistoryChanges(body): Observable<any> {
    return this.http.post<any>(this.urlNuevaBase + '/add-producto-history-changes', body);
  }

  getProductoHistoryChanges(body): Observable<any> {
    return this.http.get<any>(this.urlNuevaBase + '/get-producto-history-changes', {params:body});
  }
}
