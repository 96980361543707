import {Component, OnInit} from '@angular/core';
import {ReportesService} from '../../core/services/dashboard/reportes.service';
import {CantidadesTotales} from '../../core/Interface/dashboard/cantidades-totales';
import {LoginService} from '../../core/services/login.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  empresaSeleccionada = new FormControl(0);
  cantidades: CantidadesTotales;
  permisos: any = JSON.parse(sessionStorage.getItem('permisos'));
  nombre: string;
  datosSelector;

  constructor(private reportesService: ReportesService,
              private users: LoginService) {}

  ngOnInit() {
    this.inicializarSelector();
    this.getCantidades();
    this.getUser();
  }

  inicializarSelector() {
    if (this.users.getEmpresaUser() === 8) {
      this.datosSelector = [
        {
          id: 8,
          empresa: 'Shoefiti'
        }
      ];
      this.empresaSeleccionada.setValue(8);
      this.empresaSeleccionada.disable();
    } else {
      this.datosSelector = [
        {
          id: 0,
          empresa: 'Todas'
        },
        {
          id: 1,
          empresa: 'Sneaker Store'
        },
        {
          id: 2,
          empresa: 'Religion Sneakers'
        },
        {
          id: 4,
          empresa: 'Golden Laces'
        },
        {
          id: 5,
          empresa: 'Aplicación'
        }
      ];
    }
  }

  getUser() {
    this.users.getUserById().subscribe(data => {
      this.nombre = data.name;
      this.users.getPermisosModal(data.id).subscribe(data => {
        if (!data.permisos) {
          sessionStorage.setItem('permisos_modal', "{}")
        }else {
          sessionStorage.setItem('permisos_modal', data.permisos);
        }
      })
    });
  }

  getCantidades() {
    this.cantidades = null;
    this.reportesService.getCantidades(+this.empresaSeleccionada.value).subscribe(data => this.cantidades = data);
  }

}
