import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Permisos} from '../../Interface/usuarios/permisos';

@Injectable({
  providedIn: 'root'
})
export class AdministradorPermisosService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl + 'administrador-permisos';
  }

  getAll(): Observable<Permisos[]> {
    return this.http.get<Permisos[]>(this.url);
  }

  getById(idPermiso: number): Observable<Permisos> {
    return this.http.get<Permisos>(this.url + '/' + idPermiso);
  }

  getActivos(): Observable<Permisos[]> {
    return this.http.get<Permisos[]>(this.url + '/' + 'activos');
  }

  post(permiso: any): Observable<Permisos> {
    return this.http.post<Permisos>(this.url, permiso);
  }

  put(idPermiso: number, permiso: any): Observable<any> {
    return this.http.put(this.url + '/' + idPermiso, permiso);
  }
}
