import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ProductoValorAtributo} from '../../Interface/productos/ProductoValorAtributo';
import {Producto} from '../../Interface/productos/producto';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiURLNuevaBase + 'productos';
  }

  get(): Observable<Producto[]> {
    return this.http.get<Producto[]>(this.url);
  }

  getByIdEmpresa(idEmpresa): Observable<any[]> {
    return this.http.get<any[]>(this.url + `/byIdEmpresa/${idEmpresa}`);
  }
  getProductoOrden():Observable<any[]>{
    return this.http.get<any[]>(this.url+ `/productos-salida`)
  }
  getInterna(idEmpresa: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/interna/${idEmpresa}`);
  }
  
  postSugerencia(body): Observable<any> {
    return this.http.post(`${this.url}/sugerencias`, body);
  }

  getDiferencia(idOrden: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/diferencia/${idOrden}`);
  }

  getTallasById(idEmpresaProducto: number) {
    return this.http.get<any[]>(this.url + `/tallas/${idEmpresaProducto}`);
  }

  getTallasByProductoId(idProducto: number, idEmpresa: number) {
    return this.http.get<any[]>(this.url + `/tallas-activas/${idProducto}/${idEmpresa}`);
  }

  getTallaById(id: number) {
    return this.http.get<any[]>(this.url + `/talla-id/${id}`);
  }

  post(body: any): Observable<ProductoValorAtributo[]> {
    return this.http.post<ProductoValorAtributo[]>(this.url, body);
  }

  getColors() {
      return this.http.get<any[]>(this.url + `/get-colores`);
  }

  getColoresActivos(body: any): Observable<any> {
    return this.http.post(this.url + '/get-producto-colores',  body);
  }

  updateProductoColores(body: any): Observable<any> {
    return this.http.post(this.url + '/update-producto-colores',  body);
  }

  AddColor(body: any): Observable<any> {
    return this.http.post(this.url + '/add-colores',  body);
  }

  GetProductCatalog(): Observable<any>
  {
    return this.http.get<any[]>(this.url + `/get-products`);
  }

  getProductosRelacionados(body: any): Observable<any> {
    return this.http.post(this.url + '/get-productos-relacionados',  body);
  }

  updateProductosRelacionados(body: any): Observable<any> {
    return this.http.post(this.url + '/update-producto-relacionado',  body);
  }

  getProductoUserComment(body:any): Observable<any> {
    return this.http.post(environment.apiUrl + `verificacion/get-product-rates-by-product-id`, body);
  }

  updateActivoProductoRate(body:any): Observable<any> {
    return this.http.post(environment.apiUrl + `verificacion/update-activo-producto-rate`, body);
  }

  addProductoHistoryChanges(body): Observable<any> {
    return this.http.post<any>(this.url + '/add-producto-history-changes', body);
  }

  addKart(customer): Observable<any>{
    return this.http.post(this.url + '/add-customer-kart-admin',customer)
  }
}
