import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {

  url: any;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl;
  }

  getOrders(): Observable<any[]> {
    return this.http.get<any[]>(this.url + 'ordenes');
  }

  updatePago(data): Observable<any> {
    return this.http.post<any>(this.url + 'updatePagoStatus', data);
  }

  getRecibo(id): Observable<any> {
    return this.http.get<any>(this.url + 'recibo/' + id);
  }

  getOrdenById(id): Observable<any> {
    return this.http.get<any>(this.url + 'getOrdenById/' + id);
  }

  getById(id): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/id/' + id);
  }

  getOrdenByLote(idLote: number): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/get-lote/' + idLote);
  }

  getOrdenByMetodoPago(idPago: number): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/get-metodo-pago/' + idPago);
  }

  getOrdenByEmpresa(idEmpresa: number): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/empresa/' + idEmpresa);
  }

  getOrdenByStatus(idStatus: number): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/status/' + idStatus);
  }

  getOrdenByOrigen(idOrigen: number): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/origen/' + idOrigen);
  }

  getOrdenByFecha(fecha: number): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/fecha/' + fecha);
  }

  getOrdenesPendientes(): Observable<any> {
    return this.http.get<any>(this.url + 'ordenes/pendientes');
  }

  getOrdenesSinContacto(): Observable<any> {
    return this.http.get(this.url + 'ordenes/sin-contacto');
  }

  getFactura(idOrden: number): Observable<any> {
    return this.http.get(this.url + 'pdf/' + idOrden,  { observe: 'body', responseType: 'blob' as 'json' });
  }

  getAbandonados(): Observable<any> {
    return this.http.get(environment.apiURLNuevaBase + 'verificacion/get-abandonos-automaticos');
  }

  getDireccionOrden(idDireccion: number): Observable<any> {
    return this.http.get(this.url + 'getDireccion/' + idDireccion);
  }

  updateEstado(id, estado): Observable<any> {
    return this.http.put(this.url + 'update-estado/' + id + '/' + estado + '/' + jwt_decode(sessionStorage.getItem('token')).sub, null);
  }

  updateEstadoMercadoPago(id, estado): Observable<any> {
    return this.http.put('http://api.sneakerstore.com.mx/api/update-estado/' + id + '/' + estado, null);
  }

  updateOrdenATusManos(imagenes): Observable<any> {
    return this.http.post(this.url + 'update-estado-tus-manos', imagenes);
  }

  updateFechaAprobacion(id): Observable<any> {
    return this.http.put(this.url + 'fecha-aprobacion/' + id + '/' + jwt_decode(sessionStorage.getItem('token')).sub, null);
  }

  updateFechaContacto(id): Observable<any> {
    return this.http.put(this.url + 'fecha-contacto/' + id + '/' + jwt_decode(sessionStorage.getItem('token')).sub, null);
  }

  updateEstadoContacto(id, idEstadoContacto): Observable<any> {
    return this.http.put(this.url + 'ordenes/estado-contacto/' + id + '/' + idEstadoContacto, null);
  }

  updateNumeroMP(id, numeroMp: string): Observable<any> {
    return this.http.put(this.url + 'ordenes/update-numero-mp/' + id + '/' +
      numeroMp + '/' + jwt_decode(sessionStorage.getItem('token')).sub, null);
  }

  updateLote(id: number, idLote?: number): Observable<any> {
    let url = this.url + 'ordenes/update-lote/' + id;

    if (idLote) {
      url += '/' + idLote ;
    }

    return this.http.put( url , null);
  }

  updateMetodoPago(id: number, idMetodoPago?: number): Observable<any> {
    let url = this.url + 'ordenes/update-metodo-pago/' + id;

    if (idMetodoPago) {
      url += '/' + idMetodoPago ;
    }

    return this.http.put( url , null);
  }

  ordenesParaLotes(): Observable<any> {
    return this.http.get(this.url + 'ordenes/lotes');
  }

  reenviarFactura(idOrden: number): Observable<any> {
    return this.http.post(this.url + 'reenviar-factura/' + idOrden, null);
  }

  ordenesPagadasAprobadas(): Observable<any> {
    return this.http.get(this.url + 'ordenes/pagadas-aprobadas');
  }

  saveComprobantePago(imagen): Observable<any> {
    return this.http.post(this.url + 'saveRecibo', imagen);
  }

  getEncuesta(): Observable<any> {
    return this.http.get(this.url + 'registrar-encuesta-satisfaccion');
  }

  getAprobadasSemanales(): Observable<any> {
    return this.http.get(this.url + 'ordenes/aprobadas-semanales');
  }

  postCambio(orden): Observable<any> {
    return this.http.post(`${environment.apiURLNuevaBase}ordenes/ecommerce/cambio`, orden);
  }

  validateProductOnRightStock(body) {
    return this.http.post(`${environment.apiURLNuevaBase}verificacion/validate-product-on-right-stock`, body);
  }

  addChangeHistory(body): Observable<any> {
    return this.http.post(`${environment.apiURLNuevaBase}verificacion/add-change-history`, body);
  }
}
