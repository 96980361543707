import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Marca} from '../../Interface/productos/marca';

@Injectable({
  providedIn: 'root'
})
export class MarcasService {

  url: any;
  urlNuevaBase: any;
  urlSubMarca: any;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl + 'marcas';
    this.urlSubMarca = environment.apiUrl + 'submarcas';
    this.urlNuevaBase = environment.apiURLNuevaBase;
  }

  public get(): Observable<any[]> {
    return this.http.get<any[]>(this.url);
  }

  public getSubMarcas(): Observable<any[]> {
    return this.http.get<any[]>(this.urlSubMarca);
  }

  public getRs(): Observable<any[]> {
    return this.http.get<any[]>(this.url + '-rs');
  }

  public getById(id: number): Observable<Marca> {
    return this.http.get<Marca>(this.url + '/' + id);
  }

  public getBusmarcaById(id: number): Observable<any> {
    return this.http.get<any>(this.urlSubMarca + '/' + id);
  }

  public getByEmpresa(idEmpresa: number): Observable<Marca[]> {
    return this.http.get<Marca[]>(this.url + '/id-empresa/' + idEmpresa);
  }

  public post(marca): Observable<any> {
    return this.http.post<any>(this.url, marca);
  }

  public postSubmarca(submarca): Observable<any> {
    return this.http.post<any>(this.urlSubMarca, submarca);
  }

  public put(id: number, marca: Marca): Observable<any> {
    return this.http.put<any>(this.url + '/' + id, marca);
  }

  public putSubMarca(id: number, marca: any): Observable<any> {
    return this.http.put<any>(this.urlSubMarca + '/' + id, marca);
  }

  /*******************************************Nueva base***************************************************/
  public getMarcas(): Observable<any[]> {
    return this.http.get<any[]>(this.urlNuevaBase + 'productos/catalogos/marcas/activas');
  }
}
