import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AdministradorOrdenes } from '../../Interface/sales/AdministradorOrdenes';

@Injectable({
  providedIn: 'root'
})
export class OrdenesService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiURLNuevaBase + 'ordenes';
  }

  get(): Observable<AdministradorOrdenes[]> {
    return this.http.get<AdministradorOrdenes[]>(this.url);
  }

  getByEstado(estado: number): Observable<AdministradorOrdenes[]> {
    return this.http.get<AdministradorOrdenes[]>(this.url + '/by-estado/' + estado);
  }

  getByVendedor(estado: number, vendedor: string): Observable<AdministradorOrdenes[]> {
    return this.http.get<AdministradorOrdenes[]>(`${this.url}/by-vendedor/${estado}/${vendedor}`);
  }

  getByFilters(vendedor: string, estado: number): Observable<AdministradorOrdenes[]> {
    if (estado && !vendedor) {
      return this.http.get<AdministradorOrdenes[]>(this.url + '/by-estado/' + estado);
    }
    if (!estado && vendedor) {
      return this.http.get<AdministradorOrdenes[]>(`${this.url}/by-vendedor/${vendedor}`);
    }
    if (estado && vendedor) {
      return this.http.get<AdministradorOrdenes[]>(`${this.url}/by-filters/${estado}/${vendedor}`);
    }
  }

  updateEstado(datosEstado: { id: number, estado: number }) {
    return this.http.put(this.url + '/update-estado', datosEstado);
  }

  updateEstadoContracargo( id: string, estatus: number) {
    console.log(`Estado enviado: ${estatus}`);
    return this.http.put(this.url + '/update-estado-contracargo', { id, estatus});
  }

  updateMetodoPago(datosEstado: { idOrden: number, idMetodoPago: number }) {
    return this.http.put(this.url + '/update-metodo-pago', datosEstado);
  }
  updateAbonoOrden(body) {
    return this.http.post(`${this.url}/update-abono-orden`, body);
  }

  getLastOrders(): Observable<any> {
    return this.http.get(this.url + '/ultimas-ordenes');
  }
  getContracargoHistory(): Observable<any>{
    return this.http.get(this.url + '/historial-contracargo')
  }

  // Este servicio nos notifica si una orden ya está lista para ser mandada al cliente
  getEstadoOrden(idOrden: number): Observable<any> {
    return this.http.get(`${this.url}/listo-envio/${idOrden}`);
  }

  getProductosListosParaEnvio(idOrden: number): Observable<any> {
    return this.http.get(`${this.url}/productos-listos-envio/${idOrden}`);
  }

  getDetalleOrden(idOrden: number): Observable<any> {
    return this.http.get(`${this.url}/detalle/${idOrden}`);
  }

  getAllDetalles(): Observable<any> {
    return this.http.get(`${this.url}/detalle-all`);
  }

  getAbonosOrden(numeroFolio: any) {
    return this.http.get(`${this.url}/get-abonos-orden/${numeroFolio}`);
  }
  getOrderByFolio(numeroFolio: any) {
    return this.http.get(`${this.url}/get-by-folio/${numeroFolio}`);
  }

  getFactura(numeroFolio: string) {
    return this.http.get(`${this.url}/factura/${numeroFolio}`, { observe: 'body', responseType: 'blob' as 'json' });
  }

  reenviarFactura(numeroFolio: string, idEmpresa: any) {
    let urlNew = this.url;

    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlNew = urls[parseInt(idEmpresa)];
    }

    return this.http.post(`${urlNew}/reenviar-factura/${numeroFolio}`, null);
  }

  postAbonoApartado(body) {
    return this.http.post(`${this.url}/abono-apartado`, body);
  }
  addContracargoHistory(body): Observable<any>{
    return this.http.post(`${this.url}/crear-historial-contracargo`,body);
  }
  postAbonoCincuenta(body) {
    return this.http.post(`${this.url}/abono-cincuenta`, body);
  }
  postApartado(body, idEmpresa?: number, vendedor?: number): Observable<any> {
    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/apartado`, body);
  }

  postCincuenta(body, idEmpresa?: number, vendedor?: number): Observable<any> {
    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/cincuenta`, body);
  }
  post(body, idEmpresa?: number, vendedor?: number): Observable<any> {

    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/interna`, body);
  }

  cambiarMetodoPagoEmail(body, idEmpresa?: number): Observable<any> {
    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/mandar-correo`, body);
  }

  updateVisibleState(body): Observable<any> {
    return this.http.post(environment.apiURLNuevaBase + 'ordenes/update-visible-state', body);
  }

  correoApartado(body, idEmpresa?: number): Observable<any> {
    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/mandar-correo-apartado`, body);
  }

  correoCincuenta(body, idEmpresa?: number): Observable<any> {
    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/mandar-correo-cincuenta`, body);
  }

  correoClipProspecto(body, idEmpresa?: number): Observable<any> {
    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/correo-clip-prospecto`, body);
  }

  correoProspecto(body: Object, idEmpresa?: number): Observable<any> {
    let urlCorreo = this.url;
    if (idEmpresa) {
      const urls = {
        1: `${this.url}`,
        2: 'https://api-prueba.sneakerstore.com.mx/nueva-base/ordenes',
        4: 'https://backend.desarrollatech.com/nueva-base/ordenes',
        8: 'https://backend-shoefiti.desarrollatech.com/nueva-base/ordenes',
        9: 'https://backend-forwalk.desarrollatech.com/nueva-base/ordenes'
      };
      urlCorreo = urls[idEmpresa];
    }
    return this.http.post(`${urlCorreo}/prospecto-metodo-pago`, body);
  }

  misOrdenes(): Observable<any> {
    return this.http.get(`${this.url}/mis-ordenes`);
  }

  pasarAPagada(body) {
    return this.http.put(`${this.url}/pasar-pagada`, body);
  }

  ordenEnviadaCliente(body): Observable<any> {
    return this.http.put(`${this.url}/orden-enviada-cliente`, body);
  }

  agregarMotivo(body): Observable<any> {
    return this.http.post(this.url + '/update-not-wanted', body);
  }

  getNotWantedStatus(body): Observable<any> {
    return this.http.post(this.url + '/get-not-wanted-status-by-email', body);
  }

  updateOrderPrice(body): Observable<any> {
    return this.http.post(this.url + '/update-order-price', body);
  }

  updateOrderStatus(body): Observable<any> {
    return this.http.post(this.url + '/update-order-status', body);
  }

  addOrderStock(body): Observable<any> {
    return this.http.post(this.url + '/add-order-producto-stock', body);
  }

  deleteOnlyOrderProductoStock(body): Observable<any> {
    return this.http.post(this.url + '/delete-only-order-producto-stock', body);
  }

  deleteAllOrderProductoStock(body): Observable<any> {
    return this.http.post(this.url + '/delete-all-order-producto-stock', body);
  }

  deleteOnlyOrderProductoGoodStock(body): Observable<any> {
    return this.http.post(this.url + '/delete-only-order-producto-good-stock', body);
  }

  crearSubasta(body): Observable<any> {
    return this.http.post(this.url + '/crear-subasta', body);
  }

  getSubastas(): Observable<any> {
    return this.http.get(this.url + '/get-subastas');
  }

  GetSubastaProductos(body): Observable<any> {
    return this.http.post(this.url + '/get-subasta-productos', body);
  }

  updateSubastaFinishDate(body): Observable<any> {
    return this.http.post(this.url + '/update-date-finish', body);
  }

  GetSubastaProductoOfertas(body): Observable<any> {
    return this.http.post(this.url + '/get-subasta-producto-ofertas', body);
  }

  UploadOrdenGuide(body): Observable<any> {
    return this.http.post(this.url + '/upload-orden-guide', body);
  }

  getGuideFromFolio(body): Observable<any> {
    return this.http.post(this.url + '/get-guide-from-folio', body);
  }

  AsignUploadedGuideFromFolio(body): Observable<any> {
    return this.http.post(this.url + '/asign-uploaded-guide-from-folio', body);
  }

  finishSubasta(body): Observable<any> {
    return this.http.post(this.url + '/finish-subasta', body);
  }

  GetLastRewardCupon(body): Observable<any> {
    return this.http.post(this.url + '/get-last-reward-cupon', body);
  }

  ReAsignWinner(body): Observable<any> {
    return this.http.post(this.url + '/re-asign-winner', body);
  }

  getOrderIdByFolio(numeroFolio: any): Observable<any> {
    return this.http.get(`${this.url}/get-order-id-by-folio/${numeroFolio}`);
  }

  AddOrderProductoStockMultiple(body): Observable<any> {
    return this.http.post(this.url + '/add-order-producto-stock-multiple', body);
  }

  PostCotizacion(body) {
    return this.http.post(this.url + '/post-cotizacion', body);
  }

  GetReporteCotizacion($folio) {
    return this.http.get(this.url + `/reporte-cotizacion/${$folio}`, { observe: 'body', responseType: 'blob' as 'json' })
  }

  OrdenIrrecuperable(body) : Observable<any> {
    return this.http.post(this.url + '/orden-irrecuperable', body);
  }
  // comentarios de los productos
  getOrdenComment(){
    return this.http.get(this.url + '/producto/comments');
  }
  updateCommentStatus(id: number, Estatus: string){
    return this.http.post(this.url + `/producto/comments/status/${id}`,{Estatus});
  }
  createComment(body){
    return this.http.post(this.url+ `/producto/comments`, body);
  }
  getCommentGenerate(promptKey: string): Observable<any> {
    return this.http.post(`${this.url}/producto/comments/generate-text`, {
      "prompt_key": promptKey
    });
  }
}
