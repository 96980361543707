import {Component, OnInit, Output, EventEmitter, isDevMode} from '@angular/core';
import {NavService} from '../../service/nav.service';
import {LoginService} from '../../../core/services/usuarios/login.service';
import {OrdersService} from '../../../core/services/orders.service';
import {MercadoPagoService} from '../../../core/services/finanzas/mercado-pago.service';
import { MatDialog } from '@angular/material/dialog';
import {ModalAgregarTareaComponent} from '../modal-agregar-tarea/modal-agregar-tarea.component';
import { Usuarios } from 'src/app/core/Interface/finanzas/catalogos/Usuarios';
import { CrearTicketService } from 'src/app/core/services/sales/catalogos/crear-ticket.service';
import { Router } from '@angular/router';
import { ModalBuscadorComponent } from '../modal-buscador/modal-buscador.component';
import { AsistenteComponent } from '../asistente/asistente.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar = false;
  public open = false;
  public openNav = false;
  public isOpenMobile: boolean;
  ordenes: any;
  nameAuth: string;
  tickets: any;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService,
              private loginService: LoginService,
              private ordenesService: OrdersService,
              private ticketsService: CrearTicketService,
              private dialog: MatDialog,
              private router: Router,
              private mercadoPagoService: MercadoPagoService) {

                // this.ticketsService.ticketsUsuario(sessionStorage.getItem('user_name')).subscribe(data => this.tickets = data);

  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  logout() {
    this.loginService.logout().subscribe({
      complete: () => {
        sessionStorage.clear();
        window.location.href = '/auth/login';
      }
    });
  }

  ngOnInit() {
    if (!isDevMode()) {
      this.mercadoPagoService.put().subscribe();
    }

    this.iniciarSesion().then(() => {
      this.cargarTickets();
    });
  }

  private iniciarSesion(): Promise<void> {
    return this.loginService.sesiones().toPromise();
  }

  private cargarTickets(): void {
    const userName = sessionStorage.getItem('user_name');
    if (userName) {
      this.ticketsService.ticketsUsuario(userName).subscribe(data => {
        this.tickets = data;
        // Aquí puedes realizar otras acciones después de cargar los tickets.
      });
    } else {
      // Manejar el caso en el que 'user_name' no está presente en sessionStorage.
    }
  }

  abrirModalTareas() {
    this.dialog.open(ModalAgregarTareaComponent, {
      width: '450px',
    });
  }

  detalleTickets() {
    this.router.navigate(['/administrador/tickets']);
  }

  abrirBuscador() {
    this.dialog.open(ModalBuscadorComponent, {
      width: '1050px',
    }).afterClosed().subscribe(() => {
      console.log("Saliendo")
    });
  }

  abrirAsistente() {
    this.dialog.open(AsistenteComponent, {
      width: '600px',
      maxHeight: '400px',
      panelClass: 'asistente',
    }).afterClosed().subscribe(() => {});
  }

}
