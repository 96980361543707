import { Component, OnInit,Renderer2  } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Empresa } from 'src/app/core/Interface/productos/catalogos/empresa';
import { LoginService } from 'src/app/core/services/login.service';
import { NotificacionesService } from 'src/app/core/services/notificaciones.service';
import { EmpresasNewService } from 'src/app/core/services/productos/catalogos/empresas-new.service';
import { MarcasService } from 'src/app/core/services/productos/marcas.service';
import { ProductoService } from 'src/app/core/services/productos/producto.service';
import { ModalProductoClienteComponent } from '../modal-producto-cliente/modal-producto-cliente.component';

@Component({
  selector: 'app-modal-buscador',
  templateUrl: './modal-buscador.component.html',
  styleUrls: ['./modal-buscador.component.scss']
})
export class ModalBuscadorComponent implements OnInit {

  displayedColumns = ['imagen', 'nombre', 'precio','descuento', 'precioDescuento', 'descripcion', 'marca', 'submarca'];
  buscador = new FormControl('');
  productos: any;
  productosFiltrados: Observable<any>;
  productosSeleccionados = new MatTableDataSource([]);
  formArray = new FormArray([]);
  tallas = [];
  empresas: Empresa[];
  marcas: any[];
  idMarca: number;
  idEmpresa: number;
  vendedor;
 idProductoSelecionado: number;
 idEmpresaSeleccionada: number;
 nombreProducto: string;
 precioProductoSelecionado: number;
 EmpresaProductoSelecionada: number;
 talla: any[];


  constructor(
    private productosService: ProductoService,
    private empresasService: EmpresasNewService,
    private user: LoginService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalBuscadorComponent>,
    private marcaService: MarcasService,
    private router: Router,
    private notificiones: NotificacionesService
    ) { }

  ngOnInit() {
    this.getMarcas();
    if (this.user.getEmpresaUser() === 8) {
      this.empresas = [{
        id: 8,
        empresa: 'Shoefiti',
        activo: 1
      }];
      this.idEmpresa = 8;
      this.getProductos();
    } else {
      this.getEmpresasActivas();
    }
    this.getUserName();
  }

  getEmpresasActivas() {
    this.empresasService.getActivos().subscribe(data => this.empresas = data);
  }

  getProductos() {
    /*this.productos = null;
    this.productosSeleccionados.data = [];*/
    this.productosService.getInterna(this.idEmpresa).subscribe(data => {
      this.productos = data;
      this.productosFiltrados = this.buscador.valueChanges
        .pipe(
          startWith(''),
          map(producto => producto ? this._filterStates(producto) : this.productos.slice())
        );
    });
  }

  getMarcas() {
    this.marcaService.getMarcas()
    .subscribe(data => {
      this.marcas = data;
    });
  }

  agregarProducto(producto) {
    producto['precioTotal'] = 0;
    const productoArreglo = {...producto};
    console.log(productoArreglo)
    this.buscador.setValue('');

    productoArreglo.precioVentaOriginal = productoArreglo.precioVenta;

    productoArreglo.aplicarDescuento = false;
    productoArreglo.porcentajeDescuento = false;
    productoArreglo.valorDescuento = 0;
    this.idEmpresaSeleccionada = this.idEmpresa;
    this.nombreProducto= producto.nombre;
    this.idProductoSelecionado =  producto.idEmpresaProducto;
    this.precioProductoSelecionado = producto.precioVenta;

    if (producto.idTipoDescuento == 1) {
      productoArreglo.descuento = producto.precioVenta * (producto.descuento / 100);
    }
    this.talla = this.tallas
    
    if (producto.idDescuento) {      
      productoArreglo.precioTotal = this.getPrecioVenta(producto);
    }
    
    this.productosSeleccionados.data = [];
    this.productosSeleccionados.data.push(productoArreglo);
    this.productosSeleccionados.data = this.productosSeleccionados.data;    

    this.formArray.push(new FormGroup({
        cantidad: new FormControl(1),
        talla: new FormControl('', Validators.required),
        ignoreDiscount: new FormControl(false, Validators.required),
        aplicarDescuento: new FormControl(false, Validators.required)
    }));

    this.getTallaById(producto.idEmpresaProducto, (productoArreglo.nombre == "GUIA DE REENVIO" || productoArreglo.nombre == "GUIA DE DEVOLUCION"));
    
  }

  getTallaById(idEmpresaProducto: number, guideProduct: boolean) {
    this.productosService.getTallasById(idEmpresaProducto).subscribe({
      next: (data) => {
        console.log(data)
         let index = this.tallas.push(data) - 1;         
         if(guideProduct) {
            if(data.length > 0) {
               this.formArray.controls[index].get('talla').setValue(data[0].id);
            }
         }
      }
    });
  }

  agregarDireccion() {
    const productosCompra = [];

    this.productosSeleccionados.data.forEach((producto, index) => {
      const talla = this.formArray.value[index];
      productosCompra.push({
        ...producto,
        talla,
        idEmpresa: this.idEmpresa,
        vendedor: this.vendedor 
      });
    });
  }

  borrarProducto(index) {
    this.productosSeleccionados.data.splice(index, 1);
  }

  getTotal(): number {
    let total = 0;
    this.productosSeleccionados.data.forEach(
      (producto, index) => total += producto.precioVenta * +this.formArray.controls[index].get('cantidad').value
    );
    return total;
  }

  getPrecioVenta(producto): number {
    if (!producto.idDescuento) {
      return producto.precioVenta;
    } else {
      if (producto.idTipoDescuento === 1) {
        return producto.precioVenta - ((producto.precioVenta * producto.descuento) / 100);
      }
      return producto.precioVenta - producto.descuento;
    }
  }

  getUserName() {
    this.user.getUserById().subscribe(data => this.vendedor = data.name);    
  }

  private _filterStates(value: string): any {
    const filterValue = value.toLowerCase();

    return this.productos.filter(producto => producto.nombre.toLowerCase().includes(filterValue) === true);
  }

  changeIgnore(index)
  {
    if(this.formArray.value[index].ignoreDiscount) {
      this.productosSeleccionados.data[index].precioVenta = this.productosSeleccionados.data[index].precioVentaOriginal;
    }
    else {
      if (this.productosSeleccionados.data[index].idDescuento) {      
        this.productosSeleccionados.data[index].precioVenta = this.getPrecioVenta(this.productosSeleccionados.data[index]);
      }
    }    
  }
  generarCotizacion(){
    this.router.navigate(['/sales/crear-cotizacion'],{ queryParams: {  id: this.idProductoSelecionado, idEmpresa: this.idEmpresaSeleccionada}} );
    this.dialogRef.close();
  }

  compartirUrl(){
    
    const urlCompleta = "https://sneakerstore.com.mx/productos/detalle/" + this.nombreProducto.toLocaleLowerCase().replace(/ /g, '-');

    const tempElement = document.createElement('textarea');
    tempElement.value = urlCompleta;
    tempElement.style.position = 'fixed'; 
    document.body.appendChild(tempElement);
    tempElement.select();
    try {
      document.execCommand('copy');
      
      this.notificiones.exito('Url copiada')
    } catch (err) {
      console.error('Error al copiar al portapapeles:', err);
    }
    document.body.removeChild(tempElement);
  }

  abrirPopupVenta(){
    // const data= {
      
    // }
    this.dialog.open(ModalProductoClienteComponent,{
      maxWidth: '500px',
      maxHeight: '650px',
      data: {
      idProductoEmpresa: this.idProductoSelecionado,
      precioVenta: this.precioProductoSelecionado
      }
    })
  }
  
}
