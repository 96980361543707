import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import {ToastrService} from 'ngx-toastr';
import {Users} from '../Interface/usuarios/Users';
import { ExternalDealer } from '../Interface/usuarios/ExternalDealer';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url: any;
  urlNuevaLogica: string;
  token: any;
  urlUtils: string;
  urlDealers: string;

  constructor(private http: HttpClient, private toastrService: ToastrService) {
    this.url = environment.apiUrl;
    this.urlNuevaLogica = `${environment.apiURLNuevaBase}usuarios`;
    this.urlUtils = environment.apiURLNuevaBase + 'modals';
    this.token = sessionStorage.getItem('token');
    this.urlDealers = `${environment.apiURLNuevaBase}distribuidores`;
  }

  login(usuario): Observable<any> {
    return this.http.post<any>(`${this.urlNuevaLogica}/login`, usuario).pipe(
      map(
        (userData) => {
          sessionStorage.setItem('token', userData.token)
          return userData;
        },
        (error) => {
          this.toastrService.warning('error');
        }
      )
    );
  }

  register(usuario): Observable<any> {
    return this.http.post<any>(this.url + 'register', usuario);
  }

  getUsers(): Observable<Users[]> {
    return this.http.get<Users[]>(this.url + 'users');
  }
  getUsuarios(): Observable<any[]> {
    return this.http.get<any[]>(this.urlUtils + 'usuarios-list');
  }

  getUserById(): Observable<Users> {
    const jwt = jwt_decode(this.token);
    return this.http.get<Users>(this.url + 'users/' + jwt.sub);
  }

  getPermisosModal($idUsuario): Observable<any> {
    return this.http.get<any>(`${this.urlUtils}/permisos-modal/${$idUsuario}`);
  }
  getPassword($idUsuario): Observable<any> {
    return this.http.get<any>(`${this.urlUtils}/permisos-modal/getPass/${$idUsuario}`);
  }

  getUserExist(email: string): Observable<any> {
    return this.http.get(this.url + 'user-exist/' + email);
  }

  getEmpresaUser(): number {
    const jwt = jwt_decode(this.token);
    return +jwt.em;
  }

  validToken(): boolean {
    const now = Date.now().valueOf() / 1000;
    if (this.token != null) {
      const jwt = jwt_decode(this.token);
      return now < jwt.exp;
    } else {
      return false;
    }
  }

  getUser(): Observable<any> {
    return this.http.get<any>(`${this.urlNuevaLogica}/user-information`);
  }


  getUserInformationByPhone(data) : Observable<any> {
    const newUrl = this.url.replace("api/", "");
    return this.http.post(newUrl + "nueva-base/usuarios/get-user-information-by-phone", data)
  }

  getDealers(): Observable<ExternalDealer[]> {
    return this.http.get<ExternalDealer[]>(this.urlDealers + '/list');
  }
}
