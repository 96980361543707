import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IntegracionGPTService } from 'src/app/core/services/integracion-gpt.service';

interface ResponsesGPT {
  title: string
  respuesta: string
}

@Component({
  selector: 'app-asistente',
  templateUrl: './asistente.component.html',
  styleUrls: ['./asistente.component.scss']
})
export class AsistenteComponent implements OnInit {

  mainForm = new FormGroup({
    pregunta: new FormControl('', Validators.required)
  });

  responses: ResponsesGPT[] = [];
  cargandoRespuesta = false;

  constructor(
    private gpt: IntegracionGPTService
  ) { }

  ngOnInit(): void {
  }

  respuesta() {
    this.cargandoRespuesta = true;
    this.gpt.preguntaAsistente({text: this.mainForm.controls.pregunta.value})
    .subscribe(data => {
      for(let key in data) {
        this.responses.push({
          title: this.mainForm.controls.pregunta.value,
          respuesta: data[key]
        });
      }
      this.responses.reverse();
      this.cargandoRespuesta = false;
      this.mainForm.reset();
    })
  }

}
