import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../core/services/login.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import * as jwt_decode from 'jwt-decode';
import {AdministradorPermisosService} from '../../../core/services/usuarios/administrador-permisos.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  inicioEnProceso = false;

  constructor(private formBuilder: FormBuilder,
              private loginService: LoginService,
              private route: Router,
              private toastrService: ToastrService,
              private administradorPermisosService: AdministradorPermisosService) {
    this.createLoginForm();
  }

  ngOnInit() {
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(8)])),
    });
  }

  login() {
    this.inicioEnProceso = true;
    this.loginService.login(this.loginForm.value).subscribe({
      error: error => {
        if (error.status === 401) {
          this.toastrService.error('Credenciales incorrectas');
        }
        this.inicioEnProceso = false;
      }, complete: () => {
        const token = jwt_decode(sessionStorage.getItem('token'));

        this.administradorPermisosService.getById(token.pe).subscribe(data => {
          sessionStorage.setItem('permisos', data[0].permisos);
          sessionStorage.setItem('idPermiso', data[0].id.toString())
          
        }, () => {
          this.toastrService.error('Ocurrió un error, por favor vuelve a intentarlo más tarde');
          this.inicioEnProceso = false;
        }, () => {
          this.toastrService.success('Acceso correcto');
          sessionStorage.setItem("email", this.loginForm.controls.email.value);
          window.location.href = '/dashboard/default';
        });
      }
    });
  }

}
